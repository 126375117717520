<ng-container *ngIf="language === 'it' || language === 'en'">
  <article>
    <h1>
      INFORMATIVA SULLA PRIVACY
    </h1>
    <h3>Generalità</h3>
    <p>
      RR Solutions sas di Rudolf Rienzner & Co. ha sviluppato questa Policy
      Privacy Internet al fine di conformare la propria attività a quella di
      soggetto fidato che assicura, rispetta e mantiene il diritto alla privacy
      dei Visitatori nell'ambiente on-line.
    </p>
    <p>
      RR Solutions sas di Rudolf Rienzner & Co. una società di diritto italiano
      è il titolare del trattamento dei dati personali eventualmente raccolti
      attraverso questo sito secondo quanto definito dal Regolamento Europeo
      GDPR 679/2016. Il titolare tratterà i dati eventualmente raccolti
      attraverso questo sito "Apertura Elisuperficie occasionale" e attraverso
      l'app "Apertura Elisuperficie occasionale" per finalità, con modalità
      e secondo quanto specificamente illustrato nel seguito.
    </p>
    <br />
    <h3>Raccolta dei dati personali e finalità del trattamento</h3>
    <p>
      RR Solutions sas di Rudolf Rienzner & Co. raccoglierà e tratterà solamente
      dati personali volontariamente forniti dai visitatori di questo sito. I
      visitatori sono in grado di navigare nel sito anche senza rivelare
      informazioni e dati personali. Ciascun Visitatore del sito può scegliere
      di fornire a RR Solutions sas di Rudolf Rienzner & Co. limitati dati
      personali secondo quanto richiesto al fine di ottenere informazioni sui
      nostri servizi. Una volta registrati, i dati personali raccolti saranno
      utilizzati solamente per i servizi indicati nell'informativa disponibile.
    </p>
    <p>I Visitatori del sito possono fornire i loro dati per:</p>
    <ul>
      <li>iscriversi alla nostra newsletter</li>
      <li>iscriversi e utilizzare l'area riservata Apertura Elisuperficie Occasionale</li>
      <li>iscriversi e utilizzare l'app Apertura Elisuperficie Occasionale disponibile per IOS e Android</li>
      <li>inviare curriculum vitae</li>
      <li>richiedere informazioni sui servizi/prodotti, offerte, preventivi</li>
      <li>acquistare servizi/prodotti</li>
    </ul>
    <p>Comunicazione a terzi</p>
    <p>
      I dati personali non saranno oggetto di comunicazione ad altre società
      senza un Vostro esplicito consenso. Unica eccezione è la comunicazione
      alla RR Solutions RO SRL, Municipiul Timisoara, Strada Loichità Vasile,
      Nr. 1-3, SAD Nr. 8, Corp C, Judet Timis – Romania di Rudolf Rienzner, per
      relativi servizi che vengono eseguiti da quest`ultima per conto di RR
      Solutions sas di Rudolf Rienzner & Co. I dati personali potranno però
      essere inviati agli Enti ed Istituti per l'espletamento degli obblighi di
      legge.
    </p>
    <br />
    <h3>RR Solutions s.a.s. di Rudolf Rienzner & c.</h3>
    <p>
      I Dati sensibili, ai sensi dell'art. 4 del Regolamento Europeo GDPR
      679/2016, includono i dati personali idonei a rivelare l'origine razziale
      ed etnica, le convinzioni religiose, filosofiche o di altro genere, le
      opinioni politiche, l'adesione a partiti, sindacati, associazioni od
      organizzazioni a carattere religioso, filosofico, politico o sindacale,
      nonché i dati personali idonei a rivelare lo stato di salute e la vita
      sessuale. I dati giudiziari, sempre ai sensi dell'art. 4 del Regolamento
      Europeo GDPR 679/2016, includono i dati personali idonei a rivelare
      provvedimenti di cui all'articolo 3, comma 1, lettere da a) a o) e da r) a
      u), del D.P.R. 14 novembre 2002, n. 313, in materia di casellario
      giudiziale, di anagrafe delle sanzioni amministrative dipendenti da reato
      e dei relativi carichi pendenti, o la qualità di imputato o di indagato ai
      sensi degli articoli 60 e 61 del codice di procedura penale. Vi
      raccomandiamo di non fornire tali informazioni attraverso il nostro sito.
      Nel caso in cui ciò sia necessario (ad esempio per il caso di appartenenza
      a categorie protette in caso di invio di curriculum vitae a fini di
      assunzione, in risposta ad un annuncio di lavoro o nel caso di
      manifestazione di interesse a lavorare da noi) vi invitiamo a trasmetterci
      a mezzo lettera raccomandata dichiarazione con l'espressione del vostro
      consenso in forma scritta al trattamento di queste informazioni.
    </p>
    <br />
    <h3>Integrazione di servizi e contenuti di terze parti</h3>
    <ul>
      <li>
        Google Analytics, Google-IP-Locator, Google Maps, Google Maps Distance
        Api Eigentümer: Google Inc., 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA
      </li>
      <li>
        YouTube-Inhalte, Eigentümer: Google Inc., Amphitheatre Parkway, Mountain
        View, CA 94043, USA.
      </li>
      <li>
        Soziales Netzwerk Facebook Eigentümer: Facebook Inc., Menlo Park,
        California, USA
      </li>
      <li>
        Soziales Netzwerk Google Plus Eigentümer: Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA
      </li>
      <li>
        Soziales Netzwerk Instagram, Eigentümer: Facebook Inc., Menlo Park,
        California, USA •Soziales Netzwerk Twitter, Eigentümer: Twitter
        International Company, One Cumberland Place, Fenian Street, Dublin 2 D02
        AX07, Irland
      </li>
      <li>
        Vimeo Eigentümer: Vimeo Inc., 555 West 18th Street, New York, New York
        10011
      </li>
      <li>
        Juicer.io Eigentümer: Juicer.io, 1515 7th Street, #424, Santa Monica, CA
        90403
      </li>
    </ul>
    <br />
    <h3>Collegamenti con siti terzi</h3>
    <p>
      Si prega di notare che questo sito può contenere
      collegamenti (links) ad altri siti i quali non sono governati da questa
      policy privacy.
    </p>
    <br />
    <h3>Conservazione e cancellazione dei dati</h3>
    <p>
      Le informazioni e i dati personali dei Visitatori raccolti da questo sito,
      ivi inclusi i dati liberamente forniti al fine di ottenere l'invio di
      materiale informativo o di altre comunicazioni scrivendo nei moduli di
      contatto di questo sito saranno conservati al solo fine di fornire il
      servizio richiesto e per la durata necessaria al medesimo fine. Una volta
      completato il servizio, tutti i dati personali saranno distrutti in
      aderenza alle policy in materia di conservazione dei dati di RR Solutions
      sas di Rudolf Rienzner & Co., salvo diversa richiesta dell'autorità e
      salvo esigenze di conservazione stabilite dalla legge, o da quanto
      indicato nella presente policy misure dell'utilizzo di questo sito
      per sezioni particolari del presente sito, o secondo
      sue esplicite richieste.
    </p>
    <br />
    <h3>Esercizio dei diritti dell'interessato</h3>
    <p>
      In qualsiasi momento, ai sensi dell'art. 15 e seguenti del Regolamento
      Europeo GDPR 679/2016, sarà possibile verificare i propri dati personali
      raccolti attraverso questo sito ed eventualmente farli correggere,
      aggiornare o cancellare, o esercitare gli altri diritti riconosciuti per
      legge, scrivendo o contattando il titolare del trattamento al seguente
      indirizzo info@rrsolutions.it
    </p>
    <br />
    <h3>Sicurezza dei dati</h3>
    <p>
      Vengono applicate le misure di sicurezza tecniche e organizzative
      necessarie per proteggere i dati personali dalla perdita e dall'uso
      improprio. RR Solutions sas di Rudolf Rienzner & Co. protegge i dati
      personali attraverso l'applicazione di livelli di sicurezza riconosciuti a
      livello internazionale, nonché attraverso procedure di sicurezza che
      proteggono I dati personali contro: Daten vor Verlust und Missbrauch zu
      schützen, werden von RR Solutions KG des Rudolf Rienzner & Co. getroffen.
      RR Solutions KG des Rudolf
    </p>
    <ul>
      <li>L'accesso non autorizzato</li>
      <li>L'utilizzo improprio o la divulgazione</li>
      <li>La modifica non autorizzata</li>
      <li>
        La perdita o la distruzione accidentale o causata da atto illecito
      </li>
    </ul>
  </article>
</ng-container>
<ng-container *ngIf="language === 'de'">
  <article>
    <h1>Datenschutzerklärung</h1>
    <h3>Allgemeine Angaben</h3>
    <p>
      RR Solutions KG des Rudolf Rienzner & Co. hat die vorliegenden
      InternetDatenschutzrichtlinien ausgearbeitet, um dem Besucher der Website
      als vertrauenswürdiger Partner, der das persönliche Recht auf
      Vertraulichkeit respektiert und gewährleistet, entgegenzutreten.
    </p>
    <p>
      Im Sinne des DSGVO 2016/679 ist RR Solutions KG des Rudolf Rienzner & Co.
      Rechtsinhaber der Verwaltung bzw. Verarbeitung der personenbezogenen
      Daten, die eventuell über die Website erhoben werden. Der Rechtsinhaber
      verwaltet bzw. verarbeitet diese über die Website
      eventuell erhobenen Daten unter den im Folgenden genannten Bedingungen.
    </p>
    <br />
    <h3>Art der Datenerhebung und Zweck der Verarbeitung</h3>
    <p>
      RR Solutions KG des Rudolf Rienzner & Co. verarbeitet ausschließlich
      Daten, die der Besucher der Website aus freien Stücken angibt. Das
      Navigieren auf der Website ist auch ohne Angabe personenbezogener Daten
      und Informationen möglich. Jedem Besucher steht es frei, der RR Solutions
      KG des Rudolf Rienzner & Co. eingeschränkt personenbezogene Daten zur
      Verfügung zu stellen, um nur bestimmte Dienstleistungen in Anspruch zu
      nehmen. Erhobene und gespeicherte personenbezogene Daten werden
      ausschließlich für die im verfügbaren Informationsschreiben genannten
      Dienstleistungen verwendet.
    </p>
    <p>Die Besucher der Website können ihre Daten übermitteln, um:</p>
    <ul>
      <li>Registrierung und Nutzung des persönlichen Benutzerskontos Apertura Elisuperficie Occasionale</li>
      <li>Registrierung und Nutzung der App Apertura Elisuperficie Occasionale für IOS und Android</li>
      <li>Zusendung von CV</li>
      <li>Informationen einholen zu Dienstleistungen / Produkten / Angeboten / Kostenvoranschläge</li>
      <li>Ankauf von Dienstleistungen/Produkten</li>
    </ul>
    <p>Weitergabe der Daten an Dritte</p>
    <p>
      Die personenbezogenen Daten werden nicht an andere Unternehmen
      weitergegeben ausser es ist von gesetzlichen Bestimmungen vorsehen oder
      die Gerichtsbehörde oder andere Körperschaften und Institute fordern diese
      ein. Ausnahme bildet die RR Solutions RO SRL, Municipiul Timisoara, Strada
      Loichità Vasile, Nr. 1-3, SAD Nr. 8, Corp C, Judet Timis – Romania des
      Rudolf Rienzner im Zusammenhang mit von ihr an RR Solutions KG und CO.
      ausgeführten Dienstleistungen.
    </p>
    <br />
    <h3>RR Solutions KG des Rudolf Rienzner & Co.</h3>
    <p>
      bezweckt nicht, über die eigene Website sensible oder gerichtlich
      relevante personenbezogene Daten zu erheben. Im Sinne des Art. 4 des DSGVO
      2016/679 handelt es sich bei sensiblen Daten um persönliche Angaben zum
      Geschlecht, zur ethnischen Herkunft, zu religiösen, philosophischen oder
      anderweitigen Anschauungen, zur politischen Einstellung, zur
      Mitgliedschaft in Parteien, Gewerkschaften, Vereinen oder Organisationen
      mit religiösem, philosophischem, politischem oder gewerkschaftlichem
      Hintergrund sowie um Angaben zum Gesundheitszustand oder zur sexuellen
      Orientierung. Im Sinne des Art. 4 des DSGVO 2016/679 umfassen gerichtlich
      relevante Daten jene persönlichen Angaben, die auf Maßnahmen basierend auf
      Art. 3, Absatz 1, Buchstaben von a) bis o) und von r) bis u), des D.P.R.
      Nr. 313 vom 14. November 2002 zum Strafregister, dem Register der
      Verwaltungsstrafen infolge strafbarer Handlungen oder der diesbezüglich
      anhängenden Verfahren, oder auf die Stellung als Angeklagter oder
      Verdächtigter im Sinne der Artikel 60 und 61 der Strafprozessordnung
      schließen lassen. Wir empfehlen Ihnen, solche Informationen nicht auf
      unserer Webseite anzugeben.Sollte dies unumgänglich sein (beispielsweise
      im Fall der Zuordnung zu geschützten Personengruppen bei Zusendung eines
      Lebenslaufs zu Anstellungszwecken, in Beantwortung eines Arbeitsangebots
      oder bei Bekundung des Interesses an einer Anstellung im unserer Firma),
      ersuchen wir Sie, uns per Post das auf dieser Website im Abschnitt
      Zusendung des Lebenslaufs verfügbare spezifische Aufklärungsschreiben zu
      übermitteln. Darin geben Sie bitte Ihre schriftliche Zustimmung zur
      Behandlung besagter persönlicher und sensibler Daten.
    </p>
    <br />
    <h3>Einbindung von Diensten und Inhalten Dritter</h3>
    <ul>
      <li>
        Google Analytics, Google-IP-Locator, Google Maps, Google Maps Distance
        Api Eigentümer: Google Inc., 1600 Amphitheatre Parkway, Mountain View,
        CA 94043, USA
      </li>
      <li>
        YouTube-Inhalte, Eigentümer: Google Inc., Amphitheatre Parkway, Mountain
        View, CA 94043, USA.
      </li>
      <li>
        Soziales Netzwerk Facebook Eigentümer: Facebook Inc., Menlo Park,
        California, USA
      </li>
      <li>
        Soziales Netzwerk Google Plus Eigentümer: Google Inc., 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA
      </li>
      <li>
        Soziales Netzwerk Instagram, Eigentümer: Facebook Inc., Menlo Park,
        California, USA •Soziales Netzwerk Twitter, Eigentümer: Twitter
        International Company, One Cumberland Place, Fenian Street, Dublin 2 D02
        AX07, Irland
      </li>
      <li>
        Vimeo Eigentümer: Vimeo Inc., 555 West 18th Street, New York, New York
        10011
      </li>
      <li>
        Juicer.io Eigentümer: Juicer.io, 1515 7th Street, #424, Santa Monica, CA
        90403
      </li>
    </ul>
    <br />
    <h3>Links zu Internetauftritten Dritter</h3>
    <p>
      Wir weisen darauf hin, dass die Webseite Links auf
      andere Websites enthält, die nicht im Sinne der vorliegenden
      Datenschutzrichtlinien verwaltet werden.
    </p>
    <br />
    <h3>Aufbewahrung und Löschung der Daten</h3>
    <p>
      Die über diese Website erhobenen Informationen und personenbezogenen
      Daten, inklusive jener, die der Besucher zum Erhalt von
      Informationsmaterial oder anderen Zusendungen freiwillig angibt, indem er
      an die im Abschnitt Kontaktanfrage, Newsletter, Jobs und Online-Kauf
      angegebenen Adressen schreibt, werden ausschließlich zur Erbringung der
      gewünschten Leistung und nur für den dafür unbedingt nötigen Zeitraum
      aufbewahrt. Nach Erbringung der Dienstleistung werden alle
      personenbezogenen Daten gemäß Datenschutzerklärung, vorbehaltlich anders
      lautender behördlicher Aufforderungen oder der gesetzlich geforderten
      Aufbewahrung, gelöscht.
    </p>
    <br />
    <h3>Ausübung zustehender Rechte</h3>
    <p>
      Der Dateninhaber hat laut Art. 15 des DSGVO 2016/679 zu jeder Zeit das
      Recht, Auskunft zum Inhalt seiner personenbezogenen, über diese Website
      erhobenen Daten zu erhalten, diese eventuell richtig stellen,
      aktualisieren oder löschen zu lassen oder andere Rechte, die ihm
      gesetzlich zuerkannt sind, geltend zu machen. Dazu ist es notwendig, den
      Verantwortlichen für die Datenverarbeitung oder den
      Datenschutzbeauftragten über die E-Mail zu kontaktieren.
    </p>
    <br />
    <h3>Datensicherheit</h3>
    <p>
      Alle notwendigen technischen und organisatorischen Sicherheitsmaßnahmen,
      um die personenbezogenen Daten vor Verlust und Missbrauch zu schützen,
      werden von RR Solutions KG des Rudolf Rienzner & Co. getroffen. RR
      Solutions KG des Rudolf Rienzner & Co. schützt die personenbezogenen Daten
      über international anerkannte Sicherheitsstandards sowie über Verfahren,
      die
    </p>
    <ul>
      <li>den unbefugten Zugriff</li>
      <li>die unerlaubte Benutzung und Verbreitung</li>
      <li>unerlaubte Änderung</li>
      <li>
        den Verlust sowie unbeabsichtigtes oder rechtswidriges Zerstören
        unmöglich machen.
      </li>
    </ul>
  </article>
</ng-container>
