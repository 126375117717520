export class User {
	public id: number;
	public firstName: string;
	public lastName: string;
	public email: string;
	public cc_email: string;
	public password: string;
	public phone: string;
	public status: number;
	public createdAt: string;
	public updatedAt: string;
	public activationCode: string;
	public recoverCode: string;
	public pdfName: string;
	public pdfAddress: string;
	public pdfPostCode: string;
	public pdfCity: string;
	public pdfEmail: string;
	public pdfPEC: string;
	public pdfPhone: string;
	public pdfFax: string;
  public language: string;
}
