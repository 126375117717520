import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export class CustomValidators {


  /*
    Validator that checks if input contains any of the listed special characters
  */

  static minSpecialCharacter(control: AbstractControl): { [key: string]: any } | null {
    if (control.value) {

      const specialCharacters = ["@","$","!","%","*","?"]

      for (let i = 0; i < specialCharacters.length; i++) {
        if (control.value.includes(specialCharacters[i])) {
          return null
        }
      }

      return {
        minSpecial: true
      }
    }

    return null

  }

  /*
    Validator that checks if input contains at least one small and one capital letter
  */

  static minCapSmall(control: AbstractControl): { [key: string]: any } | null {
    if (control.value && control.value!="") {
      const isAnyUpper = (string: string) => /[A-Z]/.test(string)
      const isAnyLower = (string: string) => /[a-z]/.test(string)

      if (isAnyUpper(control.value) && isAnyLower(control.value)) {
        return null
      }

      return {
        minCapSmall: true
      }

    }

    return null

  }

  /*
    Validator that checks if input contains at least one number
  */

  static minNumber(control: AbstractControl): { [key: string]: any} | null {
    if (control.value && control.value!="") {
      const anyNumber = (string: string) => /[0-9]/.test(string)

      if (anyNumber(control.value)) {
        return null
      }

      return {
        minNumber: true
      }
    }

    return null

  }

  /*
    Validator that checks if input contains prohibited characters
  */

  static prohibitedCharacters(control: AbstractControl): { [key:string]: any} | null {

    const excludeCharacters = ["#","&"]

    for (let i = 0; i < excludeCharacters.length; i++) {
      if(control.value.includes(excludeCharacters[i])) {
        return {
          prohibited: true
        }
      }
    }

    if (control.value.indexOf(' ')>=0) {
      return {
        prohibited: true
      }
    }

    return null
  }

  /*static checkIfMatchingPasswords(pass1: string, pass2: string): ValidatorFn {
    return (control: AbstractControl): {[key:string]:any} | null => {
      if (!control.value || pass1 == pass2) {
        return null
      }

      return {
        passEqual: true
      }
    }
  }*/

  public static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string, passwordConfirmRequired = false) {
    return (group: FormGroup) => {
        const passwordInput = group.controls[passwordKey],
            passwordConfirmationInput = group.controls[passwordConfirmationKey];
        if (passwordConfirmRequired) {
            if (passwordConfirmationInput.value === undefined) {
                return passwordConfirmationInput.setErrors({ required: true });
            }
        }
        if (passwordInput.value !== passwordConfirmationInput.value) {
            return passwordConfirmationInput.setErrors({ notEquivalent: true });
        } else {
            return passwordConfirmationInput.setErrors(null);
        }
    };
}

}
