<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="{
    allowUnsort: sortingSettings.allowUnsort,
    mode: sortingSettings.multiple ? 'multiple' : 'single'
  }"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="!hiddenForMobile"
  [resizable]="!hiddenForMobile"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="margin-top: 10px !important"
  (dataStateChange)="onStateChange($event)"
  (edit)="editHandler($event)"
  (remove)="removeConfirmHandler($event)"
  (add)="addHandler()"
>
  <ng-template kendoGridToolbarTemplate class="grid-toolbar">
    <div class="title-bar row" style="display:flex; width:100%;">
      <kendo-grid-spacer></kendo-grid-spacer>
      <div class="maintitle"><h2>{{'PILOTS.pageTitle' | translate}}</h2></div>
      <kendo-grid-spacer></kendo-grid-spacer>
    </div>
    <div class="search-bar row" style="display:block; width:100%;">
      <div class="search-item">
        <span class="search-title">
          <span class="secondary-icon k-icon k-i-search"></span>
          {{'SHARED.grid.openSearch.label' | translate}}
        </span>
        <input
          [style.width.px]="300"
          placeholder="{{'SHARED.grid.openSearch.field' | translate}}"
          kendoTextBox
          (input)="onFilter($event)"
        />
      </div>
      <div class="search-item">
        <span class="insert-title">
          {{'SHARED.grid.insert.label' | translate}}
        </span>
        <button kendoGridAddCommand class="insert-button" icon="plus" themeColor="secondary" fillMode="solid">{{'PILOTS.grid.insert.buttonLabel' | translate}}</button>
      </div>
    </div>
  </ng-template>
  <kendo-grid-command-column *ngIf="hiddenForMobile"
    title="{{'PILOTS.pageTitle' | translate}}"
    [columnMenu]="false"
    [headerClass]="'gridcolumn header'"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="responsive-row"><b>{{'PILOTS.grid.table.name' | translate}} {{dataItem.firstName}} {{dataItem.lastName}}</b></span>
        <span class="responsive-row"><b>{{'PILOTS.grid.table.licenseNumber' | translate}}: </b> {{dataItem.licenseNumber}}</span>
        <span class="responsive-row"><b>{{'PILOTS.grid.table.phone' | translate}}: </b> {{dataItem.phone}}</span>
        <span class="responsive-row"><b>{{'PILOTS.grid.table.createdAt' | translate}}: </b> {{dataItem.createdAt}}</span>
        <span class="responsive-row">
          <button kendoGridEditCommand icon="edit" size="small" fillMode="solid" themeColor="primary"></button>&nbsp;
          <button kendoGridRemoveCommand icon="trash" size="small" fillMode="solid" themeColor="secondary"></button>
        </span>
    </ng-template>
  </kendo-grid-command-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="firstName"
    title="{{'PILOTS.grid.table.firstName' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="lastName"
    title="{{'PILOTS.grid.table.lastName' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="licenseNumber"
    title="{{'PILOTS.grid.table.licenseNumber' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="phone"
    title="{{'PILOTS.grid.table.phone' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="createdAt"
    title="{{'PILOTS.grid.table.createdAt' | translate}}"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-command-column title="command" [width]="150" *ngIf="!hiddenForMobile">
    <ng-template kendoGridCellTemplate>
      <button kendoGridEditCommand icon="edit" size="small" fillMode="solid" themeColor="primary"></button>
      <button kendoGridRemoveCommand icon="trash" size="small" fillMode="solid" themeColor="secondary"></button>
    </ng-template>
  </kendo-grid-command-column>
</kendo-grid>
<pilots-edit-dialog
  [model]="editDataItem"
  [isNew]="isNew"
  [blockInsertError]="blockInsertError"
  [subscriptionExpiredError]="subscriptionExpiredError"
  (save)="saveHandler($event)"
  (cancel)="cancelHandler()"
  (goToSubscriptions)="goToSubscriptionsHandler()"
>
</pilots-edit-dialog>
<kendo-dialog
  title="{{'SHARED.dialogConfirmDelete.title' | translate}}"
  *ngIf="dialogConfirmDeleteActive"
  (close)="dialogConfirmDeleteHandler('no')"
  [minWidth]="250"
  [width]="450"
>
  <p style="margin: 30px; text-align: center;">
    {{'SHARED.dialogConfirmDelete.text' | translate}}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="dialogConfirmDeleteHandler('no')">
      {{'SHARED.dialogConfirmDelete.no' | translate}}
    </button>
    <button kendoButton (click)="dialogConfirmDeleteHandler('yes')" themeColor="secondary">
      {{'SHARED.dialogConfirmDelete.yes' | translate}}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
