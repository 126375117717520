import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Pilot } from "src/app/shared/models/pilot.model";

@Component({
  selector: 'pilots-edit-dialog',
  templateUrl: './pilots-edit-dialog.component.html',
  styleUrls: ['./pilots-edit-dialog.component.scss']
})

export class PilotsEditDialogComponent{

  public active = false;
  public editForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    firstName: new FormControl("", Validators.required),
    lastName: new FormControl("", Validators.required),
    licenseNumber: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.required)
  });

  @Input() public isNew = false;
  @Input() public blockInsertError = false;
  @Input() public subscriptionExpiredError = false;

  @Input() public set model(pilot: Pilot | undefined) {
    this.editForm.reset(pilot);

    // toggle the Dialog visibility
    this.active = pilot !== undefined;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<Pilot> = new EventEmitter();

  @Output() goToSubscriptions: EventEmitter<undefined> = new EventEmitter();


  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  public navigateSubscriptions() {
    this.active = false;
    this.goToSubscriptions.emit();

  }
}
