import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Plan } from "../../shared/models/plan.model";
import { catchError, map, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpUtils } from "../../shared/http-utils";
import * as moment from "moment";

enum EndpointRoutes {
  GET_USER_PLANS = '/plans/get/byDate',
  GET_ALL_PLANS = '/plans/all/byDate',
}

@Injectable({
  providedIn: 'root'
})
export class PlansMapService {
  constructor(private _http: HttpClient) {
  };

  public getPlansByDateRange(start: Date, end: Date, isAdmin: boolean = false): Observable<Plan[]> {
    const params = {
      from: moment(start).format('YYYY-MM-DD'),
      to: moment(end).format('YYYY-MM-DD'),
    }

    const endpoint = isAdmin ? EndpointRoutes.GET_ALL_PLANS : EndpointRoutes.GET_USER_PLANS;

    return this._http.get(environment.serverUrl + endpoint, {
      headers: HttpUtils.createHeaders(),
      params: params,
    }).pipe(
      map((responseData) => this.editPlansResponse(responseData)),
      catchError(errorRes => throwError(() => errorRes))
    );
  }

  private editPlansResponse(responseData: any): Plan[] {
    const res = responseData.map((data: any) => {
      let plan = new Plan();
      plan.id = data.id;
      plan.orarioDecollo = this.formatDisplayDate(data.orarioDecollo);
      plan.partenzaLocation = data.partenzaLocation;
      const parsedArrivoNorth = this.parseCoordinate(data.arrivoNorth);
      const parsedArrivoEast = this.parseCoordinate(data.arrivoEast);
      if(parsedArrivoNorth === null || parsedArrivoEast === null)  return null;
      plan.arrivoNorth = parsedArrivoNorth.toString();
      plan.arrivoEast = parsedArrivoEast.toString();
      plan.createdAt = this.formatDisplayDate(data.createdAt);
      plan.arrivo = data.arrivo;
      plan.orarioAtterraggio = this.formatDisplayDate(data.orarioAtterraggio);
      plan.pilotaResponsabile = data.pilotaResponsabile;
      plan.tipoMarcheAeromobile = data.tipoMarcheAeromobile;
      return plan;
    }).filter((plan: any) => plan !== null); // remove plans that have invalid coordinates

    return res;
  }

  private formatDisplayDate(date: any): string {
    if (typeof date !== 'string') return '';
    return new Date(date).toLocaleString('it', {
      dateStyle: 'full',
      timeStyle: 'short',
    });
  }

  private parseCoordinate(coordinate: string): number | null {
    let result: number | null = null;
    let degrees: number;
    let minutes: number;
    let seconds: number;
    let direction: string;

    if (coordinate === null) return result;

    // remove quotes and extra spaces
    coordinate = coordinate.trim().replace(/  +/g, ' ');

    // extract parts using different delimiters
    const degIndex = coordinate.indexOf('deg');
    const minIndex = coordinate.indexOf("'");
    const secIndex = coordinate.indexOf('"');

    if (degIndex > 0 && minIndex > 0 && secIndex > 0) {
      degrees = parseInt(coordinate.substring(0, degIndex).trim(), 10);
      minutes = parseInt(coordinate.substring(degIndex + 3, minIndex).trim(), 10);
      seconds = parseFloat(coordinate.substring(minIndex + 1, secIndex).trim());
      direction = coordinate.substring(secIndex + 1).trim().split(' ')[0];

      // check if all required parts are present
      if (!isNaN(degrees) && !isNaN(minutes) && !isNaN(seconds) && direction) {
        // calculate decimal degrees
        result = degrees + minutes / 60 + seconds / 3600;

        // add negative symbol if south or west
        if (direction === 'S' || direction === 'W') {
          result = -result;
        }

        // check for invalid coordinates
        if (result < -90 || result > 90){
          result = null;
        }
      } else {
        result = null; // invalid pattern
      }
    } else {
      result = null; // invalid pattern
    }

    return result;
  }

}
