import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationService } from '@progress/kendo-angular-notification';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { Invoice } from 'src/app/shared/models/invoice';
import { Subscription } from 'src/app/shared/models/subscription';
import { SharedService } from 'src/app/shared/shared.service';
import { environment } from 'src/environments/environment';
import { SubscriptionsService } from '../subscriptions.service';

@Component({
  selector: 'app-cart-payment-paypal',
  templateUrl: './cart-payment-paypal.component.html',
  styleUrls: ['./cart-payment-paypal.component.scss']
})
export class CartPaymentPaypalComponent implements OnInit {
  public disablePayButton = false;
  public subscription: Subscription;
  public subscriptionId: number;
  public currentStep: string = 'payWithPaypal'; //'invoice';
  public payPalConfig?: IPayPalConfig;
  public showSuccess: boolean = false;
  public invoice: Invoice;

  private _requestCode: string;

  constructor(
    private _notificationService: NotificationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _sharedService: SharedService,
    private _subscriptionsService: SubscriptionsService,
  ) {}

  ngOnInit() {
    const requestCode = this._activatedRoute.snapshot.params['rc'];
    if (!requestCode) {
      return this._router.navigate(['/packages']);
    }
    this._requestCode = requestCode;
    this._subscriptionsService
      .getUserInvoiceByRequestCode(this._requestCode)
      .subscribe({
        next:(result) => {
          this.subscription = result['subscription'];
          this.invoice = result['invoice'];
          console.log('this.subscription:', this.subscription);
          console.log('this.invoice:', this.invoice);
          this.invoice = result['invoice'];
          if (this.invoice.paymentStatus === 0) {
            this._initPaypalConfig();
          } else {
            this._router.navigate(['/subscriptions']);
          }
        },
        error:(error) => {
          this._router.navigate(['/subscriptions']);
        }
      });
    return;
  }
  private _initPaypalConfig(): void {
    const lsLocale = localStorage.getItem('locale');
    let paypalLocale = 'en_GB'
    switch (lsLocale?.toLowerCase()) {
      case 'it':
        paypalLocale = 'it_IT';
        break;
      case 'de':
        paypalLocale = 'de_DE';
        break;
      case 'en':
        paypalLocale = 'en_GB';
        break;
    }

    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.PAYPAL_CLIENT_ID, //environment.PAYPAL_CLIENT_ID, 'sb'
      createOrderOnClient: (data: any) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'EUR',
                value: this.subscription.price.toString(),
                breakdown: {
                  item_total: {
                    currency_code: 'EUR',
                    value: this.subscription.price.toString(),
                  },
                },
              },
              items: [
                {
                  name: 'Apertura Elisuperficie Occasionale - ' + this.subscription.name,
                  quantity: '1',
                  category: 'DIGITAL_GOODS',
                  unit_amount: {
                    currency_code: 'EUR',
                    value: this.subscription.price.toString(),
                  },
                },
              ],
            },
          ],
          payer: {
            name: {
              given_name: this.invoice.companyName.split(' ')[0],
              surname: (this.invoice.companyName.split(' ')[1]) ? this.invoice.companyName.split(' ')[1] : 'NoLastName'
            },
            email_address: this.invoice.email,
            address: {
              country_code: 'IT',
              address_line_1: this.invoice.address,
              address_line_2: this.invoice.address2,
              postal_code: this.invoice.postalCode,
              admin_area_2: this.invoice.city
            },
            phone: {
              phone_number: {
                national_number: this.invoice.phone
              }
            }
          },
          application_context: {
            brand_name: 'Apertura Elisuperficie Occasionale - RR Solutions sas'
          }
        },
      advanced: {
        commit: 'true',
        locale: paypalLocale
      },
      style: {
        label: 'paypal',
        layout: 'vertical',
        tagline: false,
        height: 55,
        shape: 'rect',
        color: 'gold',
      },
      onApprove: (data, actions) => {
        console.log(
          'onApprove - transaction was approved, but not authorized',
          data,
          actions
        );
        actions.order.get().then((detail: any) => {
          console.log(
            'onApprove - you can get full order details inside onApprove: ',
            detail
          );
        });
      },
      onClientAuthorization: (data) => {
        console.log(
          'onClientAuthorization - you should probably inform your server about completed transaction at this point',
          data
        );
        this.showSuccess = true;
        this._subscriptionsService.assignUserSubscription(this._requestCode, data.id).subscribe((result)=>{
          this._subscriptionsService.getInvoiceDataOfUser().subscribe(() => {
            this._sharedService.setSubscriptionToken(result['subscription']);
            this._router.navigate(['/subscriptions/paymentresult/','success', this._requestCode, data.id]);
          });
        });

      },
      onCancel: (data, actions) => {
        this._subscriptionsService.invoiceUpdate(this._requestCode, 'OnCancel-Data:::'+JSON.stringify(data)).subscribe(()=> { // if cancelled this end point will insert the json paypal response object in description field like a string
          this._router.navigate(['/subscriptions/paymentresult/','cancelled', this._requestCode, '0']);
        });
      },
      onError: (err) => {
        this._subscriptionsService.invoiceUpdate(this._requestCode, 'OnCancel-Data:::'+JSON.stringify(err)).subscribe(()=> { // if failed this end point will insert the json paypal response object in description field like a string
          this._router.navigate(['/subscriptions/paymentresult/','failed', this._requestCode, '0']);
        });
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
      },
    };
  }

}
