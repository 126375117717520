import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LocalStorageService, UserLocalStorageObject } from '../shared/local-storage.service';

class LoginReturnData {
  success!: boolean;
  userId!: number;
  token!: string;
  subscription!: string;
  language!: string;
}

export class LoginReturnError {
  stringCode!: string;
  value!: string | { waitTime: string };
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {

  userData = new BehaviorSubject<UserLocalStorageObject|null>(null);

  constructor(private _http: HttpClient, private _router: Router, private _localStorage: LocalStorageService) { }

  // properties
  public get currentUserDataValue(): UserLocalStorageObject | null {
    const userData: UserLocalStorageObject = this._localStorage.getUserLocalStorageObject();
    return userData;
  }

  private _userLocalStorageObject: UserLocalStorageObject;

  // methods
  loginPost(userEmail: string, password: string) {
    return this._http.post<LoginReturnData>(environment.serverUrl + '/user/login', { userEmail, password, platform: '0'})
      .pipe(
        map(resData => {
          localStorage.clear();
          const userData = new UserLocalStorageObject(resData.userId, resData.token, resData.subscription, resData.language);
          console.log('loginPost_userData:', userData);
          this.userData.next(userData);
        }),
        catchError(this._handleError)
      );
  }

  resetPassword(username: string) {
    return this._http.post<String>(environment.serverUrl + '/user/recoverPassword', { username })
      .pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  verifyCode(recoveryCode: string) {
    let params = new HttpParams();
    params = params.append('recoveryCode', recoveryCode);
    return this._http.get<String>(environment.serverUrl + '/user/validateRecoveryCode', { params: params })
      .pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  createPassword(recoveryCode: string, password: string) {
    return this._http.post<String>(environment.serverUrl + '/user/changeRecoveryPassword', { recoveryCode, password })
      .pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

  validateRecoveryCode(recoveryCode: string) {
    return this._http.get(environment.serverUrl + '/user/validateRecoveryCode', { params: { recoveryCode } }).pipe(
      map((res: any) => res)
    );
  }

  public activateUser(activationCode: string): Observable<any> {
    return this._http.get(environment.serverUrl + '/user/validate', { params: { activationCode } }).pipe(
      map((res: any) => res)
    );
  }

  autoLogin() {
    const userData: UserLocalStorageObject = JSON.parse(localStorage.getItem(environment.userSessionUniqueId) || '{}');
    if (!userData) {
      return;
    }
    const loadedUser = new UserLocalStorageObject(
      userData.id,
      userData.sessionToken,
      userData.subscription,
      userData.locale
    );
    if (loadedUser.sessionToken) {
      this.userData.next(loadedUser);
    }
  }

  logout() {
    this.userData.next(null);
    localStorage.clear();
    this._router.navigate(['/login']);
  }

  private _handleError(reponseError: HttpErrorResponse): Observable<LoginReturnError> {
    let errorCode: LoginReturnError;
    switch (reponseError.status) {
      case 610:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
      case 611:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
      case 612:
        errorCode = {stringCode: 'accountDisabled', value: reponseError.error};
        break;
      case 613:
        errorCode = {stringCode: 'accountNotEnabled', value: reponseError.error};
        break;
      default:
        errorCode = {stringCode: 'wrongCredentials', value: reponseError.error};
        break;
    }

    return throwError(errorCode);
  }
}
