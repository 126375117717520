import { Plan } from "../../../shared/models/plan.model";

export class MarkerData {
  plan: Plan;
  marker: google.maps.Marker;
  circle: google.maps.Circle;
  windowContent: string;

  constructor(plan: Plan, marker: google.maps.Marker, circle: google.maps.Circle, windowContent: string) {
    this.plan = plan;
    this.marker = marker;
    this.circle = circle;
    this.windowContent = windowContent;
  }
}
