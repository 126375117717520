<div class="login-container" fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh;">
  <div class="login-box">
    <div class="logo-container row">
      <div>
        <img src="../../assets/media/images/logo_login.png" title="Apertura Eli-Avio-Idro Superficie Occasionale" alt="Apertura Eli-Avio-Idro Superficie Occasionale">
      </div>
    </div>
    <div class="title-container">
      <div kendoTypography variant="h5" textAlign="center" [margin]="{ top: 0, bottom: 0}" themeColor="light">{{'PASSWORDRECOVERY.title' | translate}}</div>
    </div>
    <div class="form-container">
      <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onRecovery()" [formGroup]="recoveryForm">
        <fieldset class="k-form-fieldset">
          <legend class="k-form-legend">{{'PASSWORDRECOVERY.subtitle' | translate}}</legend>
          <div class="row">
            <kendo-formfield class="col-12">
              <kendo-label [for]="email" text="{{'PASSWORDRECOVERY.fieldFirstName.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="email"
                #email
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>
                <div class="row" [innerHTML]="'PASSWORDRECOVERY.fieldFirstName.label' | translate"></div>
              </kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-buttons">
            <div class="login-button-row">
              <button kendoButton themeColor="primary" type="submit" class="button-primary" [disabled]="submitButtonDisabled">{{'PASSWORDRECOVERY.submitButtonText' | translate}}</button>
            </div>
            <div class="other-buttons-row">
              <button kendoButton themeColor="secondary" fillMode="outline" class="button-link" (click)="openLoginPage()" [innerHTML]="'PASSWORDRECOVERY.redirectToLoginButtonText' | translate"></button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span><b>Powered by RR Solutions</b></span><br/>
        <span>Via Brennero 9a, 39100 Bolzano</span><br/>
        <span>Brennerstrasse 9a, 39100 Bozen</span><br/>
        <span><a href="mailto:info@rrsolutions.it" target="_blank">info@rrsolutions.it</a></span> |
        <span><a href="//www.rrsolutions.it" target="_blank">www.rrsolutions.it</a></span>
        <div class="poweredby-logo">
          <img src="assets/media/images/Logox1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
