import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Aircraft } from 'src/app/shared/models/aircraft.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_AIRCRAFTS = '/helicopters/get',
  INSERT_AIRCRAFT = '/helicopter/insert',
  UPDATE_AIRCRAFT = '/helicopter/update',
  DELETE_AIRCRAFT = '/helicopter/delete'
}

@Injectable({
  providedIn: 'root'
})
export class AircraftsService {

  constructor(private _http: HttpClient) { }


  public getAircrafts(): Observable<Aircraft[]> {
    return this._http.get<Aircraft[]>(environment.serverUrl + EndpointRoutes.GET_AIRCRAFTS, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getAircraft(aircraftId: string): Observable<Aircraft> {
    return this._http.get<Aircraft>(environment.serverUrl + EndpointRoutes.GET_AIRCRAFTS, { headers: HttpUtils.createHeaders(), params: { id: aircraftId } }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public saveAircraft(aircraftObject: Aircraft): Observable<Aircraft> {
    return this._http.put<Aircraft>(environment.serverUrl + EndpointRoutes.INSERT_AIRCRAFT, { helicopter: aircraftObject }, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public updateAircraft(aircraftObject: Aircraft): Observable<Aircraft> {
    return this._http.put<Aircraft>(environment.serverUrl + EndpointRoutes.UPDATE_AIRCRAFT, { helicopter: aircraftObject }, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public deleteAircraft(id: string): Observable<any> {
    return this._http.delete(environment.serverUrl + EndpointRoutes.DELETE_AIRCRAFT, { headers: HttpUtils.createHeaders(), params: { id } }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }
}
