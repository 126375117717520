import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import * as moment from 'moment';
import { User} from 'src/app/shared/models/user.model';
import { AccountService } from './account.service';
import { UserLocalStorageObject } from 'src/app/shared/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit{


  public formGroup: FormGroup;
  public userId: number;
  public user: User;
  public userSubscription: any;
  public daysLeft = 0;
  public expirationDateFormat = '';
  public userData: UserLocalStorageObject;
  public languagesSource: Array<{ text: string; value: number }> = [];
  public languagesArray: Array<{ text: string; value: number }>;

  constructor(private _notificationService: NotificationService, private _sharedService: SharedService,
    private _accountService: AccountService, private _formBuilder: FormBuilder, private _router: Router,
    private _translate: TranslateService) {
      this.formGroup = this._formBuilder.group({
        id: ['', <any>Validators.required],
        firstName: ['', <any>Validators.required],
        lastName: ['', <any>Validators.required],
        email: [{ value: '', disabled: true }, <any>Validators.required],
        cc_email: ['', [Validators.required, Validators.email]],
        phone: ['', <any>Validators.required],
        language: [null, <any>Validators.required],
        pdfName: [''],
        pdfAddress: [''],
        pdfPostCode: [''],
        pdfCity: [''],
        pdfEmail: ['', <any>Validators.email],
        pdfPEC: ['', <any>Validators.email],
        pdfPhone: [''],
        pdfFax: [''],
        password: ['', <any>Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,20})$')],
        repeatPassword: ['', <any>Validators.pattern('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&])([a-zA-Z0-9@$!%*?&]{8,20})$')],
      });
  }

  public ngOnInit(): void {
    this.userSubscription = this._sharedService.getSubscriptionTokenObject();
    console.log('subscription', this.userSubscription);
    this.languagesSource = this._sharedService.getLanguages();
    this.languagesArray = this.languagesSource.slice();
    const dayDiff = moment.duration(moment(this.userSubscription.to).diff(moment().format('YYYY-MM-DD')));
    this.expirationDateFormat = moment(this.userSubscription.to).format('DD/MM/YY');
    this.daysLeft = parseFloat(dayDiff.asDays().toFixed(0));
    this._getUserAndSetForm();
  }

  private _getUserAndSetForm() {
    this._accountService.getUser().subscribe(result => {
      this.formGroup.patchValue(result);
      this.formGroup.controls["id"].setValue(result.id);
      this.formGroup.controls["firstName"].setValue(result.firstName);
      this.formGroup.controls["lastName"].setValue(result.lastName);
      this.formGroup.controls["email"].setValue(result.email);
      this.formGroup.controls["cc_email"].setValue(result.cc_email);
      this.formGroup.controls["phone"].setValue(result.phone);
      this.formGroup.controls["pdfName"].setValue(result.pdfName);
      this.formGroup.controls["pdfAddress"].setValue(result.pdfAddress);
      this.formGroup.controls["pdfPostCode"].setValue(result.pdfPostCode);
      this.formGroup.controls["pdfCity"].setValue(result.pdfCity);
      this.formGroup.controls["pdfEmail"].setValue(result.pdfEmail);
      this.formGroup.controls["pdfPEC"].setValue(result.pdfPEC);
      this.formGroup.controls["pdfPhone"].setValue(result.pdfPhone);
      this.formGroup.controls["pdfFax"].setValue(result.pdfFax);
      this.languagesSource.forEach(lang => {
        if (lang.value.toString() == result.language) {
          this.formGroup.controls["language"].setValue({text:lang.text, value:lang.value.toString()});
        }
      });
    });
  }

  public saveChanges(): void {
    this.formGroup.markAllAsTouched();
    if ( this.formGroup.valid) {
      if (this.formGroup.value['password']) {
        if (this.formGroup.value['password'] !== this.formGroup.value['repeatPassword']) {
          const notification: NotificationRef =
          this._notificationService.show({
            content: 'Errore! Le due password devono essere identiche!',
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });
          return;
        }
      }
      const user : User = new User();
      user.id = this.formGroup.controls["id"].value;
      user.firstName = this.formGroup.controls["firstName"].value;
      user.lastName = this.formGroup.controls["lastName"].value;
      user.email = this.formGroup.controls["email"].value;
      user.cc_email = this.formGroup.controls["cc_email"].value;
      user.phone = this.formGroup.controls["phone"].value;
      user.language = this.formGroup.controls["language"].value.value;
      user.pdfName = this.formGroup.controls["pdfName"].value;
      user.pdfAddress = this.formGroup.controls["pdfAddress"].value;
      user.pdfPostCode = this.formGroup.controls["pdfPostCode"].value;
      user.pdfCity = this.formGroup.controls["pdfCity"].value;
      user.pdfEmail = this.formGroup.controls["pdfEmail"].value;
      user.pdfPEC = this.formGroup.controls["pdfPEC"].value;
      user.pdfPhone = this.formGroup.controls["pdfPhone"].value;
      user.pdfFax = this.formGroup.controls["pdfFax"].value;
      user.password = this.formGroup.controls["password"].value;

      this._accountService.updateUser(user).subscribe({
        complete:()=> {

          console.log('userLanguage:', user.language);
          console.log('lsLocale', localStorage.getItem('locale'));

          let languageChanges = false;

          if (user.language !== localStorage.getItem('locale')) {
            languageChanges = true;
            this._sharedService.getLoggedUser().subscribe(accountData => {
              console.log('accountData', JSON.stringify(accountData));
              localStorage.setItem('accountData', JSON.stringify(accountData));
              localStorage.setItem('locale', accountData.language);
              this._sharedService.setCookie('locale',  accountData.language, 365);
              this._translate.use(accountData.language);
              localStorage.setItem(environment.appSessionUniqueId, JSON.stringify({ 'locale':  accountData.language }));
              const menus = this._sharedService.getMenus( accountData.language);
              localStorage.setItem('menus', JSON.stringify(menus));
            });
          }
          this.formGroup.patchValue({ password: '', repeatPassword: '' });
          this._notificationService.show({
            content: this._translate.instant('SHARED.messages.accountUpdated'),
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'success', icon: true },
            hideAfter: 3000,
          }).afterHide?.subscribe(()=>{
            window.location.reload();
          });
        },
        error: (error) => {
          console.log('error___this._accountService.updateUser', error)
          const notification: NotificationRef =
            this._notificationService.show({
              content: error.error,
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'error', icon: true },
              hideAfter: 3000,
            });
        }
      });
    };
  }

  public handleWorkerTypeFilter(value: any) {
    this.languagesArray = this.languagesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

}
