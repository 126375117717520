<kendo-dialog
    *ngIf="active"
    [width]="'80%'"
    [maxWidth]="400"
    [height]="'80%'"
    (close)="closeForm()"
>
    <kendo-dialog-titlebar>
    {{ isNew ? ('AIRCRAFTS.grid.form.insertTitle' | translate) :  ('AIRCRAFTS.grid.form.updateTitle' | translate) }}
    </kendo-dialog-titlebar>
    <form novalidate class="k-form" [formGroup]="editForm"  *ngIf="!blockInsertError && !subscriptionExpiredError">
        <kendo-formfield>
            <kendo-label class="label" [for]="type" text="{{'AIRCRAFTS.grid.form.fieldType.label' | translate}}"></kendo-label>
            <kendo-textbox
            formControlName="type"
            #type
            required
            ></kendo-textbox>
            <kendo-formhint>{{'AIRCRAFTS.grid.form.fieldType.hint' | translate}}</kendo-formhint>
            <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
            <kendo-label class="label" [for]="name" text="{{'AIRCRAFTS.grid.form.fieldName.label' | translate}}"></kendo-label>
            <kendo-textbox
            formControlName="name"
            #name
            required
            ></kendo-textbox>
            <kendo-formhint>{{'AIRCRAFTS.grid.form.fieldName.hint' | translate}}</kendo-formhint>
            <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
        </kendo-formfield>
    </form>
    <ng-container *ngIf="blockInsertError && !subscriptionExpiredError" class="error-template">
      <div class="block-insert info-text">
        <span class="info-title">{{'SHARED.alerts.maxAircraft.partOne' | translate}}</span><br/>
        {{'SHARED.alerts.maxAircraft.partTwo' | translate}} <br />
        {{'SHARED.alerts.maxAircraft.partThree' | translate}}  <br />
        <button class="subscriptions-button" kendoButton themeColor="secondary" fillMode="solid" (click)="navigateSubscriptions()">{{'SHARED.alerts.maxAircraft.buttonSubscription' | translate}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="subscriptionExpiredError" class="error-template">
      <div class="block-insert info-text">
        <span class="info-title">{{'SHARED.alerts.subscriptionExpired.partOneTwo' | translate}}</span> <br />
        {{'SHARED.alerts.subscriptionExpired.partThree' | translate}} <br />
        {{'SHARED.alerts.subscriptionExpired.partFour' | translate}} <br />
        <button class="subscriptions-button" kendoButton themeColor="secondary" fillMode="solid" (click)="navigateSubscriptions()">{{'SHARED.alerts.subscriptionExpired.buttonSubscription' | translate}} </button>
      </div>
    </ng-container>
    <kendo-dialog-actions>
    <button kendoButton (click)="onCancel($event)" icon="reset">{{'SHARED.form.reset' | translate}}</button>
    <button
        kendoButton
        themeColor="secondary"
        [disabled]="!editForm.valid"
        (click)="onSave($event)"
        icon="save"
    >
    {{'SHARED.form.save' | translate}}
    </button>
    </kendo-dialog-actions>
</kendo-dialog>
