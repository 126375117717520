import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { AccountComponent } from './modules/account/account.component';
import { AircraftsComponent } from './modules/aircrafts/aircrafts.component';
import { PilotsComponent } from './modules/pilots/pilots.component';
import { PlansComponent } from './modules/plans/plans.component';
import { CartPaymentPaypalComponent } from './modules/subscriptions/cart-payment-paypal/cart-payment-paypal.component';
import { CartResultPaypalComponent } from './modules/subscriptions/cart-result-paypal/cart-result-paypal.component';
import { CartComponent } from './modules/subscriptions/cart/cart.component';
import { SubscriptionsComponent } from './modules/subscriptions/subscriptions.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { PlansMapComponent } from "./modules/map/plans-map.component";

const routes: Routes = [
  {
    path: '', component: AppLayoutComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: '/main', pathMatch: 'full' },
      { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
      { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
      { path: 'aircrafts', component: AircraftsComponent, canActivate: [AuthGuard] },
      { path: 'pilots', component: PilotsComponent, canActivate: [AuthGuard] },
      { path: 'plans', component: PlansComponent, canActivate: [AuthGuard] },
      { path: 'map', component: PlansMapComponent, canActivate: [AuthGuard] },
      { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard] },
      { path: 'subscriptions/cart', component: CartComponent,canActivate: [AuthGuard] },
      { path: 'subscriptions/payment/:rc', component: CartPaymentPaypalComponent,canActivate: [AuthGuard] },
      { path: 'subscriptions/paymentresult/:ps/:rc/:tr', component: CartResultPaypalComponent,canActivate: [AuthGuard] }
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: 'reset-password/:recoveryCode', component: ResetPasswordComponent },
  { path: 'activate/:activationCode', component:UserActivationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
