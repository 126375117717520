// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: false,
  appSessionUniqueId: 'CFF75D1AD9996',
  userSessionUniqueId: 'FA25815678937',
  serverUrl: 'https://aelificie.rrsolutions.it/api/v1',
  // serverUrl: 'https://aaesobackend.rrsolutionstest.it/api/v1',
  //serverUrl: 'http://192.168.1.5:2597/api/v1/frontend/',
  KENDO_UI_LICENSE: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkxJQyJ9.eyJwcm9kdWN0cyI6W3sidHJpYWwiOmZhbHNlLCJjb2RlIjoiS0VORE9VSVJFQUNUIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjY5Njk5Mzg2fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlDT01QTEVURSIsImxpY2Vuc2VFeHBpcmF0aW9uRGF0ZSI6MTY2OTY5OTM4Nn0seyJ0cmlhbCI6ZmFsc2UsImNvZGUiOiJLRU5ET1VJVlVFIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjY5Njk5Mzg2fSx7InRyaWFsIjpmYWxzZSwiY29kZSI6IktFTkRPVUlBTkdVTEFSIiwibGljZW5zZUV4cGlyYXRpb25EYXRlIjoxNjY5Njk5Mzg2fV0sImludGVncml0eSI6InlHWTJrUHBPK1FrMXpIWjFRQThYdlNRd3ZTUT0iLCJsaWNlbnNlSG9sZGVyIjoiaXZhbi5tQHJyc29sdXRpb25zLml0IiwiaWF0IjoxNjU1OTg1MzI1LCJhdWQiOiJpdmFuLm1AcnJzb2x1dGlvbnMuaXQiLCJ1c2VySWQiOiI2MWY3MmZjZi0xZTQyLTRlNjAtOTU5Yi1hZGZjMTU1MThiOTYifQ.LYUlGPF3OHR7OYDG56-nLfYFcI7QGPV2PRdTVw1y0l165GaC8zb6nh8phpFLAnV2T0oAFFsgE5EE5vId0l7lKsJZxAG_ApkjCroaQy1YqiXlDi1Q-YKVG2QjkLnlJWqIDIGCc7Ks9OEvBe1ob3Wm6cpxt-17icFqM2X96uOa-V8x3x0cK-077tr69thDEvXhk_7iyw54lzOHlG80TRYbBG9mHjaHICJiQ02g5vQxIid6LKSDb6HjO8BV7zQSnDwQEoZe6qBeAC1D8zrtoctVFFSr4d8I77GM-NkSU7xnu7mqMt3tdC8ezFCv4625tCzD1W71qbMvn0PAKQ_YcYD7Zw',
  PAYPAL_CLIENT_ID: 'Ab9rEBi_BwNydEf-dJjxkQn_eizYgjLRhx1JbNnUFbXVu9u1n2cd-ZfTBIFe_uSFGy8hF4egcipzg-5t'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
