import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { SharedService } from '../shared/shared.service';
import { RegisterService } from './register.service';
import { User } from '../shared/models/user.model';
import { NotificationService } from '@progress/kendo-angular-notification';
import { TranslateService } from '@ngx-translate/core';
import { CustomValidators } from '../shared/customValidators';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {

  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;
  @ViewChild("repeatPassword")
  public repeatPasswordField!: TextBoxComponent;

  public registerForm: FormGroup;
  public errorMessage!: String;

  public privacyWindowOpened = false;
  public errorArray = new Array();

  private _user = new User;

  public languagesSource: Array<{ text: string; value: number }> = [];
  public languagesArray: Array<{ text: string; value: number }>;

  public currentLanguage: string

  constructor(private _formBuilder: FormBuilder, private _router: Router,
    private _registerService: RegisterService, private _sharedService: SharedService,
    private _loadingService: LoadingService, private _notificationService: NotificationService,
    private _translateService: TranslateService
    ) {

      this.registerForm = this._formBuilder.group({
        firstName: ['', <any>Validators.required],
        lastName: ['', <any>Validators.required],
        email: ['', [<any>Validators.required, <any>Validators.email]],
        emailCC: ['', [<any>Validators.required, <any>Validators.email]],
        password: ['', [<any>Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          CustomValidators.minSpecialCharacter,
          CustomValidators.minCapSmall,
          CustomValidators.minNumber,
          CustomValidators.prohibitedCharacters]],
        repeatPassword: ['', [<any>Validators.required]],
        phone: ['', <any>Validators.required],
        termsConditionsCheck: [false],
        language: [null, <any>Validators.required],
      }, {
        validators: [this.checkTermsAndConditions('termsConditionsCheck'),
          CustomValidators.checkIfMatchingPasswords('password','repeatPassword')
        ],
      });
  }

  public ngOnInit(): void {
    this.languagesSource = this._sharedService.getLanguages();
    this.languagesArray = this.languagesSource.slice();

    this.currentLanguage = this._translateService.currentLang;

  }

  public ngAfterViewInit(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
    const repeatPasswordEl = this.repeatPasswordField.input.nativeElement;
    repeatPasswordEl.type = repeatPasswordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibilityPassword(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibilityRepeat(): void {
    const repeatPasswordEl = this.repeatPasswordField.input.nativeElement;
    repeatPasswordEl.type = repeatPasswordEl.type === "password" ? "text" : "password";
  }

  public onRegisterFormSubmit(): void {
    this.registerForm.markAllAsTouched();
    this.errorArray = [];
    if (this.registerForm.valid) {
      if (this.registerForm.controls["password"].value.trim() === this.registerForm.controls["repeatPassword"].value.trim()) {
        // compose object
        this._user.firstName = this.registerForm.controls["firstName"].value;
        this._user.lastName = this.registerForm.controls["lastName"].value;
        this._user.language = this.registerForm.controls["language"].value.value;
        this._user.phone = this.registerForm.controls["phone"].value;
        this._user.email = this.registerForm.controls["email"].value;
        this._user.cc_email = this.registerForm.controls["emailCC"].value;
        this._user.password = this.registerForm.controls["password"].value;

        console.log(this._user);

        this._registerService.registerNewClientUser(this._user).subscribe({
          next: (result:any) => {
            this._notificationService.show({
              content: 'Record has been saved.',
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'success', icon: true },
              hideAfter: 2000,
            });
            this._router.navigate(['/login']);
          },
          error: (err) => {
            let errorString = ""
            switch (err.status) {
              case 601:
                errorString = "emailAlreadyExist"
            }
            this._notificationService.show({
              content: this._translateService.instant('LOGIN.errors.generalError'),
              animation: { type: 'slide', duration: 500 },
              position: { horizontal: 'center', vertical: 'bottom' },
              type: { style: 'error', icon: true },
              hideAfter: 2000,
            });
          }
        });
      } else {
        this._notificationService.show({
          content: this._translateService.instant('SHARED.form.passwordComparisonError'),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
          hideAfter: 2000,
        });
        return;
      }
    }
  }

  public clearForm(): void {
    this.registerForm.reset();
  }

  public openLoginPage(): void {
    this._router.navigate(['/login']).then(() => {
      this._loadingService.setHttpProgressStatus(false);
    });
  }

  public privacyWindowOnClick(action: string): void {
    switch (action.toLowerCase()) {
      case 'open':
        this.privacyWindowOpened = true;
        break;
      case 'close':
        this.privacyWindowOpened = false;
        break;
    }
  }

  public checkTermsAndConditions(termsConditionsCheckKey: string) {
    return (group: FormGroup) => {
      const termsAndConditionsCheck = group.controls[termsConditionsCheckKey];
      if (!termsAndConditionsCheck.value) {
        return termsAndConditionsCheck.setErrors({ notTerms: true });
      } else {
        return termsAndConditionsCheck.setErrors(null);
      }
    };
  }

  public handleWorkerTypeFilter(value: any) {
    this.languagesArray = this.languagesSource.filter(
      (s) => s.text.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  omit_special_char(event: any) {
   var k = event.charCode;
   return(k != 38 && k != 35);
  }

}
