import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';


@Component({
  selector: 'app-main-toolbar',
  templateUrl: './main-toolbar.component.html',
  styleUrls: ['./main-toolbar.component.scss']
})
export class MainToolbarComponent implements OnInit {

  @Output() public toggle = new EventEmitter();
  @Input() public selectedPage?: string;

  public currentUser: User;
  public menuIconName = 'menu';
  public locale: string | null;

  public userSubscription: any;
  public expirationDateFormat: string;
  public daysLeft: number;
  public dayLeftShowMessage: boolean;

  public toggleText = "Hide";
  public show = true;

  @ViewChild("anchor") public anchor: ElementRef;
  @ViewChild("popup", { read: ElementRef }) public popup: ElementRef;

  @HostListener("keydown", ["$event"])
  public keydown(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      this.showHidePupup(false);
    }
  }

  @HostListener("document:click", ["$event"])
  public documentClick(event: KeyboardEvent): void {
    if (event.target) {
      if (!this._contains(event.target)) {
        this.showHidePupup(false);
      }
    }
  }

  constructor(private _loginService: LoginService, public translateService: TranslateService, private _router: Router, private _sharedService: SharedService) { }

  ngOnInit(): void {
    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
      this.userSubscription = this._sharedService.getSubscriptionTokenObject();
      console.log('subscription', this.userSubscription);
      const dayDiff = moment.duration(moment(this.userSubscription.to).diff(moment().format('YYYY-MM-DD')));
      this.expirationDateFormat = moment(this.userSubscription.to).format('DD/MM/YY');
      this.daysLeft = parseFloat(dayDiff.asDays().toFixed(0));
      this.dayLeftShowMessage = this.daysLeft <= 30; //show message 30 days before expiration day
      if (localStorage.getItem('locale')) {
        this.locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'it';
      }
      this._warningAutoClose(10000);
    } else {
      this._loginService.logout();
    }
  }

  onToggleSidenav() {
    this.toggle.emit();
    this.menuIconName = (this.menuIconName === 'menu') ? 'close' : 'menu';
  }

  doUserLogout() {
    this._loginService.logout();
  }

  viewProfile() {
    if (this.menuIconName === 'close') {
      this.onToggleSidenav();
    }
    this._router.navigate(['/account']);
  }

  onWarningClick(): void {
    if (this.menuIconName === 'close') {
      this.onToggleSidenav();
    }
    this.show = !this.show;
    this.toggleText = this.show ? "Hidе" : "Show";
  }

  private _warningAutoClose(milliseconds: number): void {
   if (this.show) {
    setTimeout(() =>
    {
        this.show = false;
    },
    milliseconds);
   }
  }

  public showHidePupup(show?: boolean): void {
    this.show = show !== undefined ? show : !this.show;
    this.toggleText = this.show ? "Hide" : "Show";
  }

  private _contains(target: EventTarget): boolean {
    if (this.anchor) {
      return (this.anchor.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
      );
    } else {
      return false;
    }
  }

  navigateSubscriptions() {
    this._router.navigate(['/subscriptions']);
    this.show = false;
  }

}
