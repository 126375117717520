<mat-toolbar color="primary" class="app-toolbar mat-elevation-z6 no-print">
  <div style="padding-top:5px; padding-right: 15px;">
    <img src="../../../assets/media/images/app_logo_orange.png" title="Apertura Eli-Avio-Idro Superficie Occasionale" alt="Apertura Eli-Avio-Idro Superficie Occasionale" style="width:50px; height:auto;"/>
  </div>
  <button mat-icon-button (click)="onToggleSidenav()">
    <mat-icon>{{menuIconName}}</mat-icon>
  </button>
  <span class="spacer"></span>
  <div fxFlex fxLayout fxLayoutAlign="flex-end">
    <ul fxLayout fxLayoutGap="10px" style="list-style: none;">
      <li class="account_info">
        <span>{{'SHARED.headerWelcome' | translate}} {{ currentUser.firstName }} {{ currentUser.lastName }}</span>
      </li>
      <li class="days_left" [ngClass]="show ? 'active': ''" *ngIf="dayLeftShowMessage" #anchor>
          <button kendoButton (click)="onWarningClick()" mat-icon-button>
            <mat-icon>warning</mat-icon>
          </button>
          <kendo-popup
            [anchor]="anchor"
            (anchorViewportLeave)="show = false"
            *ngIf="show"
            class="popup"
          >
            <div class="popup-content">
              <ng-container *ngIf="userSubscription.isTrial">
                {{'SHARED.alerts.trialAboutToExpire.partOne' | translate}} <span class="value">{{daysLeft}}</span> {{'SHARED.alerts.trialAboutToExpire.partTwo' | translate}} <br/>
                {{'SHARED.alerts.trialAboutToExpire.partThree' | translate}} <br />
              </ng-container>
              <ng-container *ngIf="!userSubscription.isTrial && daysLeft >= 0">
                {{'SHARED.alerts.subscriptionAboutToExpire.partOne' | translate}} <span class="value">{{userSubscription.subscription.name}}</span> {{'SHARED.alerts.subscriptionAboutToExpire.partTwo' | translate}} <span class="value">{{daysLeft}}</span> {{'SHARED.alerts.subscriptionAboutToExpire.partThree' | translate}} <br/>
                {{'SHARED.alerts.subscriptionAboutToExpire.partFour' | translate}} <br />
              </ng-container>
              <ng-container *ngIf="!userSubscription.isTrial && daysLeft < 0">
                <span class="value">{{'SHARED.alerts.subscriptionExpired.partOne' | translate}} {{userSubscription.subscription.name}} {{'SHARED.alerts.subscriptionExpired.partTwo' | translate}}</span><br/>
                {{'SHARED.alerts.subscriptionExpired.partFour' | translate}} <br />
              </ng-container>
              <button class="subscriptions-button" kendoButton themeColor="secondary" fillMode="solid" (click)="navigateSubscriptions()">{{'SHARED.alerts.subscriptionExpired.buttonSubscription' | translate}}</button>
              <button class="close-button" kendoButton themeColor="primary" fillMode="solid" (click)="onWarningClick()">X</button>
            </div>
          </kendo-popup>
      </li>
      <li>
        <button mat-icon-button (click)="viewProfile()">
          <mat-icon>account_circle</mat-icon>
        </button>
      </li>
      <li>
        <button mat-icon-button (click)="doUserLogout()">
          <mat-icon>logout</mat-icon>
        </button>
      </li>
    </ul>
  </div>
</mat-toolbar>

