import { User } from "./user.model";

export class Pilot {
    public id: number;
    public firstName: string;
    public lastName: string;
    public licenseNumber: string;
    public phone: string;
    public status: number;
    public createdAt: string;
    public updatedAt: string;
    public user: User;
}
