import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserLocalStorageObject } from '../shared/local-storage.service';
import { User } from '../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})

export class RegisterService {
  userData = new BehaviorSubject<UserLocalStorageObject|null>(null);
  constructor(private _http: HttpClient, private _router: Router) { }

  public registerNewClientUser(user: User): Observable<any> {
    return this._http.put(environment.serverUrl + '/user/register', { user: user }).pipe(
      map((res: any) => res)
    );
  }

}
