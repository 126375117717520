import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { PlansMapService } from "./plans-map.service";
import { GoogleMap, MapInfoWindow, MapMarkerClusterer } from "@angular/google-maps";
import { Plan } from "../../shared/models/plan.model";
import { FormBuilder } from "@angular/forms";
import { FormatSettings } from "@progress/kendo-angular-dateinputs";
import { MarkerData } from "./models/marker-data.model";
import { SharedService } from "../../shared/shared.service";

@Component({
  selector: 'app-plans-map',
  templateUrl: './plans-map.component.html',
  styleUrls: ['./plans-map.component.scss']
})
export class PlansMapComponent implements OnInit, AfterViewInit {
  @ViewChild('googleMap', {static: false}) googleMap: ElementRef;
  @ViewChild(GoogleMap, {static: false}) map: GoogleMap;
  @ViewChild(MapInfoWindow, {static: false}) infoWindow: MapInfoWindow;

  @ViewChild('markerCluster') set markerCluster(cluster: MapMarkerClusterer) {
    if (!cluster) return;
    this.cluster = cluster;
  }

  private loggedEmail: string;

  searchForm = this.formBuilder.nonNullable.group({
    searchValue: '',
  });

  infoWindowOptions: google.maps.InfoWindowOptions = {};

  dateFormat: FormatSettings = {
    displayFormat: "dd/MM/yyyy",
    inputFormat: "dd/MM/yy",
  };

  options: google.maps.MapOptions = {
    center: {lat: 46.48, lng: 11.33},
    zoomControl: true,
    streetViewControl: false,
    disableDoubleClickZoom: true,
    disableDefaultUI: false,
    fullscreenControl: false,
  };

  cluster: MapMarkerClusterer;
  markers: MarkerData[] = [];

  range = {
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date() // Today's date
  };

  constructor(
    private _plansMapService: PlansMapService,
    private _sharedService: SharedService,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this._sharedService.getLoggedUser().subscribe({
      next: (res) => this.loggedEmail = res.email,
      error: () => console.log('Unable get logged user email'),
    });
  }

  ngAfterViewInit() {
   // this.getMarkersByDate();
  }

  getMarkersBySearch(event: Event) {
    const inputValue = (event.target as HTMLInputElement).value;
    if (!inputValue) return;

    this.markers = this.markers.filter(markerData => {
      const plan = markerData.plan;
      for (const prop in plan) {
        if (plan.hasOwnProperty(prop) && typeof plan[prop] === 'string') {
          if (plan[prop].toLowerCase().includes(inputValue.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
    });
  }

  public getMarkersByDate() {
    const isAdmin = this.loggedEmail === 'rienznerr@gmail.com';
    this._plansMapService.getPlansByDateRange(this.range.start, this.range.end, isAdmin).subscribe({
      next: (result) => {
        if (!result.length || result.length === 0) {
          console.log('No plans found in this area within the selected range');
          return;
        }
        this.setMarkers(result);
      }, error: (error) => console.log(error),
    });
  }

  private getRadiusVisibleArea() {
    const bounds = this.map.getBounds();
    const center = this.map.getCenter()?.toJSON();
    if (!bounds || !center || !center.lat || !center.lng) {
      console.log('No bounds or center found');
      return null;
    }
    const ne = bounds.getNorthEast().toJSON();
    return google.maps.geometry.spherical.computeDistanceBetween(
      new google.maps.LatLng(center.lat, center.lng),
      new google.maps.LatLng(ne.lat, ne.lng)
    ) / 1000; // km conversion
  }


  private formatDate(date: any): string {
    if (typeof date !== 'string') return '';
    return new Date(date).toLocaleString('it', {
      dateStyle: 'full',
      timeStyle: 'short',
    });
  }

  private setMarkers(plans: Plan[]) {
    this.markers = plans.map((plan) => this.createMarkerFromPlan(plan));
    this.markers.forEach((markerData) => {
      markerData.marker.addListener("click", (event: google.maps.MapMouseEvent) => {
        console.log(`click on marker: ${markerData.plan.id}`);
        if(!markerData.marker.getVisible()) {
          markerData.circle.setVisible(true);
          this.openInfo(event, markerData);
        } else {
          markerData.circle.setVisible(false);
          this.closeInfo();
        }
      });
    });
  }

  private createMarkerFromPlan(plan: Plan): MarkerData {
    const marker = new google.maps.Marker({
      animation: google.maps.Animation.BOUNCE,
      position: {lat: parseFloat(plan.arrivoNorth), lng: parseFloat(plan.arrivoEast)},
    });

    const circle = new google.maps.Circle({
      strokeColor: "rgb(3,96,0)",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "rgb(3,96,0)",
      fillOpacity: 0.15,
      center: marker.getPosition(),
      radius: 500,
      visible: false,
    });

    const windowContent = `
        <div style="display: flex; flex-direction: column; align-items: flex-start; padding: 10px;">
          <span style="font-weight: bold">Pilota ${plan.pilotaResponsabile}</span>
          <span>Da: ${plan.partenzaLocation} </span>
          <span>Decollo: ${plan.orarioDecollo} </span>
          <span>Atterraggio: ${plan.orarioAtterraggio} </span>
        </div>
      `;

    return new MarkerData(plan, marker, circle, windowContent);
  }


  handleClusterClick() {
    this.cluster.getClusters();
  }

  openInfo(event: google.maps.MapMouseEvent, markerData: MarkerData) {
    const plan = markerData.plan;
    this.infoWindowOptions = {
      pixelOffset: new google.maps.Size(0, -30),
      position: markerData.marker.getPosition(),
      content: `
        <div style="display: flex; flex-direction: column; align-items: flex-start; padding: 10px;">
          <span style="font-weight: bold">Pilota ${plan.pilotaResponsabile}</span>
          <span>Da: ${plan.partenzaLocation} </span>
          <span>Decollo: ${plan.orarioDecollo} </span>
          <span>Atterraggio: ${plan.orarioAtterraggio} </span>
        </div>
      `,
    };
  /*  const markerIndex = this.markers.findIndex((marker) => marker.plan.id === markerData.plan.id);
    if(markerIndex !== -1) this.markers[markerIndex].circle.setVisible(true);*/
    this.infoWindow.open();
  }

  closeInfo() {
    this.infoWindow.close();
  }

}
