import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { LoadingService } from '../loading-spinner/loading.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private _totalRequests = 0;
    constructor(private _loginService: LoginService, private _loadingService: LoadingService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._totalRequests++;
        this._loadingService.setHttpProgressStatus(true);
        const currentUser = this._loginService.currentUserDataValue;
        console.log('intercept_currentUser',currentUser);
        if (currentUser && currentUser.sessionToken) {
            request = request.clone({
                setHeaders: {
                    'Cache-Control': 'no-cache',
                    'X-Access-Token': currentUser.sessionToken,
                }
            });
        }
        return next.handle(request).pipe(
            finalize(() => {
                this._totalRequests--;
                if (this._totalRequests === 0) {
                    // setTimeout(() => {
                    this._loadingService.setHttpProgressStatus(false);
                    // }, 2000);
                }
            })
        );
    }
}
