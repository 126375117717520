import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { NotificationService } from '@progress/kendo-angular-notification';
import { LoginService } from '../login/login.service';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { User } from '../shared/models/user.model';
import { SharedService } from '../shared/shared.service';
import { CustomValidators } from '../shared/customValidators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('password')
  public passwordField!: TextBoxComponent;

  @ViewChild('repeatPassword')
  public repeatPasswordField!: TextBoxComponent;

  public resetForm: FormGroup;
  public showform = false;
  private _user = new User;
  private _recoveryCode: string;
  constructor(
    private _formBuilder: FormBuilder, private _router: Router, private _notificationService: NotificationService,
    private _loginService: LoginService, private _route: ActivatedRoute, private _translate: TranslateService,
    ) {

      this.resetForm = this._formBuilder.group({
        password: ['', [<any>Validators.required,
          Validators.minLength(8),
          Validators.maxLength(30),
          CustomValidators.minSpecialCharacter,
          CustomValidators.minCapSmall,
          CustomValidators.minNumber,
          CustomValidators.prohibitedCharacters]],
        repeatPassword: ['', [<any>Validators.required]]
      }, {
        validators: [CustomValidators.checkIfMatchingPasswords('password','repeatPassword')
        ],
      }
    );
  }

  public ngOnInit() {
    this._route.params.subscribe(params => {
      if (!params['recoveryCode']) {
        return this._router.navigate(['/login']);
      }
      this._recoveryCode = params['recoveryCode'];
      this._loginService.validateRecoveryCode(this._recoveryCode).subscribe(result => {
        this.showform = true;
      }, error => {
        return this._router.navigate(['/login']);
      });
      return;
    });
    return;
  }


  public ngAfterViewInit(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
    const repeatPasswordEl = this.repeatPasswordField.input.nativeElement;
    repeatPasswordEl.type = repeatPasswordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibility(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibilityRepeat(): void {
    const repeatPasswordEl = this.repeatPasswordField.input.nativeElement;
    repeatPasswordEl.type = repeatPasswordEl.type === "password" ? "text" : "password";
  }

  public onReset(): void {
    this.resetForm.markAllAsTouched();
    if (this.resetForm.valid) {
      if (this.resetForm.controls["password"].value.trim() === this.resetForm.controls["repeatPassword"].value.trim()) {
        // compose object
        this._user.password = this.resetForm.controls["password"].value;

        console.log(this._user);

        this._loginService.createPassword(this._recoveryCode, this._user.password).subscribe(result => {

          this._notificationService.show({
            content: this._translate.instant('RESETPASSWORD.resetPasswordConfirm'),
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'success', icon: true },
            hideAfter: 2000,
          });
          this._router.navigate(['/login']);
        });
      } else {
        this._notificationService.show({
          content: this._translate.instant('SHARED.form.passwordComparisonError: '),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
          hideAfter: 2000,
        });
        return;
      }
    }
  }

  omit_special_char(event: any) {
    var k = event.charCode;
    return(k != 38 && k != 35);
   }

}
