<div class="main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="container" style="width:90vw; min-height:80vh;">
      <div class="title-bar row" style="display:flex; width:100%;">
          <div class="maintitle"><h2>{{'SUBSCRIPTIONS.pageTitle' | translate}}</h2></div>
      </div>
      <div class="wrapper page-content row">
        <div class="col-8 col-lg-12 invoice data-box">
            <div class="title">{{'SUBSCRIPTIONS.cart.title' | translate}}</div>
            <div class="subtitle">{{'SUBSCRIPTIONS.cart.subTitle' | translate}}</div>
            <form class="k-form k-form-vertical" [formGroup]="formGroup">
              <fieldset class="k-form-fieldset">
                  <div class="row form-section">
                    <div class="row form-row">
                      <div class="col-12 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="companyName" text="{{'SUBSCRIPTIONS.cart.companyName.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="companyName" [clearButton]="true" #companyName> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.companyName.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['companyName'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="vat" text="{{'SUBSCRIPTIONS.cart.vat.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="vat" [clearButton]="true" #vat> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.vat.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['vat'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="fiscalCode" text="{{'SUBSCRIPTIONS.cart.fiscalCode.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="fiscalCode" [clearButton]="true" #fiscalCode> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.fiscalCode.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['fiscalCode'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-4 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="country" text="{{'SUBSCRIPTIONS.cart.country.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="country" [clearButton]="true" #country> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.country.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['country'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-8 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="address" text="{{'SUBSCRIPTIONS.cart.address.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="address" [clearButton]="true" #address> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.address.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['address'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="address2" text="{{'SUBSCRIPTIONS.cart.address2.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="address2" [clearButton]="true" #address2> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.address2.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['address2'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-2 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="postalCode" text="{{'SUBSCRIPTIONS.cart.postalCode.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="postalCode" [clearButton]="true" #postalCode> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.postalCode.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['postalCode'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-5 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="city" text="{{'SUBSCRIPTIONS.cart.city.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="city" [clearButton]="true" #city> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.city.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['city'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-5 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="province" text="{{'SUBSCRIPTIONS.cart.province.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="province" [clearButton]="true" #province> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.province.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['province'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="email" text="{{'SUBSCRIPTIONS.cart.email.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="email" [clearButton]="true" #email> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.email.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['email'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="phone" text="{{'SUBSCRIPTIONS.cart.phone.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="phone" [clearButton]="true" #phone> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.phone.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['phone'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="row form-row">
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="PEC" text="{{'SUBSCRIPTIONS.cart.PEC.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="PEC" [clearButton]="true" #PEC> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.PEC.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['PEC'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                      <div class="col-6 col-md-12 form-field">
                        <kendo-formfield orientation="vertical">
                          <kendo-label class="k-label" [for]="ISDNumber" text="{{'SUBSCRIPTIONS.cart.ISDNumber.label' | translate}}"> </kendo-label>
                          <kendo-textbox formControlName="ISDNumber" [clearButton]="true" #ISDNumber> </kendo-textbox>
                          <kendo-formhint>{{'SUBSCRIPTIONS.cart.ISDNumber.hint' | translate}}</kendo-formhint>
                          <kendo-formerror *ngIf="formGroup.controls['ISDNumber'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                        </kendo-formfield>
                      </div>
                    </div>
                    <div class="col-12" style="margin-top:40px; border-top: 2px solid rgba(0, 0, 0, 0.12);">
                      <kendo-formfield style="margin:0px auto; padding-top:20px; width:250px;">
                        <div class="row" style="text-align: center; background-color: #F3F3F3; border-radius: 3px; padding:20px; width:100%;">
                          <span style="padding-right:10px; font-weight: bold; line-height: 1;">{{'SUBSCRIPTIONS.cart.saveForNextTime.label' | translate}}</span>
                          <input type="checkbox" kendoCheckBox formControlName="useDefault" #useDefault style="display:inline-block;" />
                        </div>
                      </kendo-formfield>
                    </div>
                  </div>
                  <div class="buttons-section">
                      <div class="row">
                          <div class="col-12 align right">
                            <button class="checkout-button" kendoButton themeColor="primary" fillMode="solid" (click)="saveChanges()">{{'SUBSCRIPTIONS.cart.buyButton' | translate}}</button>
                            <button class="return-button" kendoButton themeColor="secondary" fillMode="solid" (click)="goBack()">{{'SHARED.buttons.goBack' | translate}}</button>
                          </div>
                      </div>
                  </div>
              </fieldset>
            </form>
        </div>
        <div class="col-4 col-lg-12 subscription data-box ">
          <div class="title">
            {{'SUBSCRIPTIONS.cart.subscription.title' | translate}}
          </div>
          <div class="subtitle">
            {{'SUBSCRIPTIONS.cart.subscription.subTitle' | translate}}
          </div>
          <div class="pricing-table" style="margin-top:20px;">
            <div class="card-body row" *ngIf="subscription" [ngClass]="{'basic': subscriptionId === 2, 'bronze': subscriptionId === 3, 'silver': subscriptionId === 4, 'gold': subscriptionId === 5 }">
              <div class="card-text">
                  <div class="flag" *ngIf="subscriptionId === 2"><span class="text">basic</span></div>
                  <div class="flag" *ngIf="subscriptionId === 3"><span class="text">bronze</span></div>
                  <div class="flag" *ngIf="subscriptionId === 4"><span class="text">silver</span></div>
                  <div class="flag" *ngIf="subscriptionId === 5"><span class="text">gold</span></div>
                  {{subscription.name}}
              </div>
              <div class="card-value">
                  <div class="desc">{{'SUBSCRIPTIONS.daysLabel' | translate}}: <span>{{subscription.subscriptionType.days}} {{'SUBSCRIPTIONS.days' | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.pilots' | translate}}: <span>{{(subscription.pilots < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.pilots.toString()) | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.aircrafts' | translate}}: <span>{{(subscription.helicopters < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.helicopters.toString()) | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.plans' | translate}}: <span>{{(subscription.plans < 0 ? 'SUBSCRIPTIONS.unlimitedF' : subscription.plans.toString()) | translate}}</span></div>
                  <div class="price">{{subscription.price | currencyFormat:'€':3:2:'.':','}}</div>
                  <div class="vat">({{'SUBSCRIPTIONS.vatIncluded' | translate}})</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
