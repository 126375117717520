import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from './models/user.model';
import { LocalStorageService, UserLocalStorageObject } from './local-storage.service';
import { Menu } from './models/menu.model';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LOCAL_STORAGE } from './constants';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from "@progress/kendo-angular-l10n";
import { KendoMessagesService } from './kendo-messages.service';

enum EndpointRoutes {
  GET_LOGGED_USER = '/user/get',
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private _http: HttpClient, public jwtHelper: JwtHelperService,
    public translate: TranslateService, private _localStorageService: LocalStorageService,
    private messages: MessageService) { }

  private _userLocalStorageObject: UserLocalStorageObject;
  private _accessToken: string;
  private _subscriptionToken: string;
  private _locale: string | null;

  public getLoggedUser(): Observable<User> {
    const lsLoggedUser = localStorage.getItem('userData')
    if (lsLoggedUser) {
      this._userLocalStorageObject = JSON.parse(lsLoggedUser);
    }
    return this._http.get<User>(environment.serverUrl + EndpointRoutes.GET_LOGGED_USER, {
      headers: {'X-Access-Token': (this._userLocalStorageObject.sessionToken) ? this._userLocalStorageObject.sessionToken : ''} })
      .pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getMenus(languageCode: string): Menu[] {
    let _menus: Menu[] = [];
    switch (languageCode) {
      case 'it':
        _menus = [
          {
              "id":2,
              "code":"aperture",
              "name":"Aperture",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":20,
              "path":'plans',
              "hidden": false,
              "children": []
          },
          {
            "id":3,
            "code":"aeromobili",
            "name":"Aeromobili",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":30,
            "path":'aircrafts',
            "hidden": false,
            "children":[]
          },
          {
            "id":4,
            "code":"piloti",
            "name":"Piloti",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":40,
            "path":'pilots',
            "hidden": false,
            "children":[]
          },
          {
              "id":5,
              "code":"account",
              "name":"Account",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"account",
              "children":[]
          },
          {
              "id":6,
              "code":"abbonamento",
              "name":"Abbonamento",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"subscriptions",
              "children":[]
          },
          {
            "id":7,
            "code":"map",
            "name":"Mappa",
            "icon":"k-i-map-marker",
            "selected":0,
            "expanded":0,
            "hasChildren":false,
            "level":1,
            "parentMenuId":0,
            "menuOrder":50,
            "hidden": false,
            "path": "map",
            "children":[]
          }
        ];
      break;
      case 'en':
        _menus = [
          {
              "id":2,
              "code":"aperture",
              "name":"Plans",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":20,
              "path":'plans',
              "hidden": false,
              "children": []
          },
          {
            "id":3,
            "code":"aeromobili",
            "name":"Aircrafts",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":30,
            "path":'aircrafts',
            "hidden": false,
            "children":[]
          },
          {
            "id":4,
            "code":"piloti",
            "name":"Pilots",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":40,
            "path":'pilots',
            "hidden": false,
            "children":[]
          },
          {
              "id":5,
              "code":"account",
              "name":"Account",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"account",
              "children":[]
          },
          {
              "id":6,
              "code":"abbonamento",
              "name":"Subscription",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"subscriptions",
              "children":[]
          },
          {
            "id":7,
            "code":"map",
            "name":"Map",
            "icon":"k-i-map-marker",
            "selected":0,
            "expanded":0,
            "hasChildren":false,
            "level":1,
            "parentMenuId":0,
            "menuOrder":50,
            "hidden": false,
            "path": "map",
            "children":[]
          }
        ];
      break;
      case 'de':
        _menus = [
          {
              "id":2,
              "code":"aperture",
              "name":"Flugmeldungen",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":20,
              "path":'plans',
              "hidden": false,
              "children": []
          },
          {
            "id":3,
            "code":"aeromobili",
            "name":"Flugzeuge",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":30,
            "path":'aircrafts',
            "hidden": false,
            "children":[]
          },
          {
            "id":4,
            "code":"piloti",
            "name":"Piloten",
            "icon":"k-i-folder",
            "selected":0,
            "expanded":0,
            "hasChildren":true,
            "level":1,
            "parentMenuId":0,
            "menuOrder":40,
            "path":'pilots',
            "hidden": false,
            "children":[]
          },
          {
              "id":5,
              "code":"account",
              "name":"Benutzerkonto",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"account",
              "children":[]
          },
          {
              "id":6,
              "code":"abbonamento",
              "name":"Abonnement",
              "icon":"k-i-folder",
              "selected":0,
              "expanded":0,
              "hasChildren":false,
              "level":1,
              "parentMenuId":0,
              "menuOrder":50,
              "hidden": false,
              "path":"subscriptions",
              "children":[]
          },
          {
            "id":7,
            "code":"map",
            "name":"Karte",
            "icon":"k-i-map-marker",
            "selected":0,
            "expanded":0,
            "hasChildren":false,
            "level":1,
            "parentMenuId":0,
            "menuOrder":50,
            "hidden": false,
            "path": "map",
            "children":[]
          }
        ];
      break;
    }
    return _menus;
  }

  public getLanguages(): any[] {
    const languages: any[] = [
      {
          text:"English",
          value:'en'
      },
      {
          text:"Italiano",
          value:'it'
      },
      {
          text:"Deutsch",
          value:'de'
      }
    ]

    return languages;
  }

  public replaceAll(str:string, find:string, replace:string) {
    var escapedFind=find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    return str.replace(new RegExp(escapedFind, 'g'), replace);
  }

  private getAccessToken(): string {
    if (!this._accessToken) {
      this._accessToken = localStorage.getItem(LOCAL_STORAGE.AAESO_TOKEN) || '';
    }
    return this._accessToken;
  }

  public getAccessTokenObject(): any {
    return this.jwtHelper.decodeToken(this.getAccessToken()).data;
  }

  public getSubscriptionTokenObject(): any {
    const lsLoggedUser = localStorage.getItem('userData')
    if (lsLoggedUser) {
      this._userLocalStorageObject = JSON.parse(lsLoggedUser);
      return this.jwtHelper.decodeToken(this._userLocalStorageObject.subscription).data;
    }
  }

  public setAccessToken(token: string) {
    this._accessToken = token;
    localStorage.setItem(LOCAL_STORAGE.AAESO_TOKEN, token);
  }
  public setSubscriptionToken(token: string) {
    this._subscriptionToken = token;
    localStorage.setItem(LOCAL_STORAGE.AAESO_SUBSCRIPTION, token);
    const lsLoggedUser = localStorage.getItem('userData')
    if (lsLoggedUser) {
      this._userLocalStorageObject = JSON.parse(lsLoggedUser);
      this._userLocalStorageObject.subscription =  token;
      localStorage.setItem('userData',JSON.stringify(this._userLocalStorageObject));
    }
  }

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
        c = ca[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return '';
  }

  public deleteCookie(name: string) {
      this.setCookie(name, '', -1);
  }

  public setCookie(name: string, value: string, expireDays: number, path: string = '') {
      let d:Date = new Date();
      d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
      let expires:string = `expires=${d.toUTCString()}`;
      let cpath:string = path ? `; path=${path}` : '';
      document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  public manageLocale() {
    this.translate.addLangs(['it', 'en', 'de']);

    const browserLang = this.translate.getBrowserLang();
    const cookieLang = this.getCookie("locale");

    if (cookieLang) {
      localStorage.setItem('locale', cookieLang);
      console.log('cookieLang', cookieLang)
    } else {
      localStorage.setItem('locale', browserLang || 'en');
    }

    if (this._localStorageService.appLocalStorageExist()) {

      const sessionObject = this._localStorageService.getAppLocalStorageObject();
      console.log('sessionObject: ', sessionObject);
      this.translate.use(sessionObject.locale || '');
      this.translate.setDefaultLang(sessionObject.locale || '');
      if (sessionObject.locale) {
        this._locale = sessionObject.locale;
      };
    } else {
      if (localStorage.getItem('locale')) {
        this._locale = localStorage.getItem('locale');
        if (this._locale) {
          this.translate.use(this._locale);
          this._localStorageService.setAppLocalStorage('locale', this._locale);
          this.translate.setDefaultLang(this._locale);
        }
      }
    }

    const svc = <KendoMessagesService>this.messages;
    svc.language = this._locale || 'it';

  }
}
