import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { LoadingService } from './loading.service';


@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements AfterViewInit {

  @ViewChild('loadingContainer') loadingContainer!: ElementRef;

  constructor(private _loadingService: LoadingService, private renderer: Renderer2) { }

  ngAfterViewInit() {
    this._loadingService.httpProgress().subscribe((status: boolean) => {

      if (status) {
        this.renderer.removeStyle(this.loadingContainer.nativeElement, 'display');
      } else {
        setTimeout(() =>
        {
            this.renderer.setAttribute(this.loadingContainer.nativeElement, 'style', 'display:none!important');
        },
        300);
      }
    });
  }

}
