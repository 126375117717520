<div class="main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="container" style="width:90vw; min-height:80vh;">
      <div class="title-bar row" style="display:flex; width:100%;">
          <div class="maintitle"><h2>{{'SUBSCRIPTIONS.pageTitle' | translate}}</h2></div>
      </div>
      <div class="wrapper page-content row">
        <div class="col-8 col-lg-12 invoice data-box">
          <div class="title">{{'SUBSCRIPTIONS.cartPaymentPaypal.title' | translate}}</div>
          <div class="subtitle">{{'SUBSCRIPTIONS.cartPaymentPaypal.subTitle' | translate}}</div>
          <div class="paypal">
            <div class="paypal-container">
              <div class="title">{{'SUBSCRIPTIONS.cartPaymentPaypal.container.title' | translate}}</div>
              <div class="subtitle">{{'SUBSCRIPTIONS.cartPaymentPaypal.container.subTitle' | translate}}</div>
              <ngx-paypal [config]="payPalConfig" class="ngxPayPal"></ngx-paypal>
            </div>
          </div>
        </div>
        <div class="col-4 col-lg-12 subscription data-box ">
          <div class="title">
            {{'SUBSCRIPTIONS.cartPaymentPaypal.subscription.title' | translate}}
          </div>
          <div class="subtitle">
            {{'SUBSCRIPTIONS.cartPaymentPaypal.subscription.subTitle' | translate}}
          </div>
          <div class="pricing-table" style="margin-top:20px;">
            <div class="card-body row" *ngIf="subscription" [ngClass]="{'basic': subscription.id === 2, 'bronze': subscription.id === 3, 'silver': subscription.id === 4, 'gold': subscription.id === 5 }">
              <div class="card-text">
                  <div class="flag" *ngIf="subscription.id === 2"><span class="text">basic</span></div>
                  <div class="flag" *ngIf="subscription.id === 3"><span class="text">bronze</span></div>
                  <div class="flag" *ngIf="subscription.id === 4"><span class="text">silver</span></div>
                  <div class="flag" *ngIf="subscription.id === 5"><span class="text">gold</span></div>
                  {{subscription.name}}
              </div>
              <div class="card-value">
                  <div class="desc">{{'SUBSCRIPTIONS.daysLabel' | translate}}: <span>{{subscription.subscriptionType.days}} {{'SUBSCRIPTIONS.days' | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.pilots' | translate}}: <span>{{(subscription.pilots < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.pilots.toString()) | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.aircrafts' | translate}}: <span>{{(subscription.helicopters < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.helicopters.toString()) | translate}}</span></div>
                  <div class="desc">{{'SUBSCRIPTIONS.plans' | translate}}: <span>{{(subscription.plans < 0 ? 'SUBSCRIPTIONS.unlimitedF' : subscription.plans.toString()) | translate}}</span></div>
                  <div class="price">{{subscription.price | currencyFormat:'€':3:2:'.':','}}</div>
                  <div class="vat">({{'SUBSCRIPTIONS.vatIncluded' | translate}})</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
