import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private _loginService: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      console.log('canActivate_userData', this._loginService.currentUserDataValue);
      // const userData: UserLocalStorageObject = JSON.parse(localStorage.getItem(environment.userSessionUniqueId) || '{}');
      const userData = this._loginService.currentUserDataValue;
        if (userData) {
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
