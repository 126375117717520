<div class="login-container" fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh;">
  <div class="login-box">
    <div class="logo-container row">
      <div>
        <img src="../../assets/media/images/logo_login.png" title="Apertura Eli-Avio-Idro Superficie Occasionale" alt="Apertura Eli-Avio-Idro Superficie Occasionale">
      </div>
    </div>
    <div class="title-container">
      <div kendoTypography variant="h5" textAlign="center" [margin]="{ top: 0, bottom: 0}" themeColor="light">{{'REGISTRATION.title' | translate}}</div>
    </div>
    <div class="form-container">
      <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onRegisterFormSubmit()" [formGroup]="registerForm">
        <fieldset class="k-form-fieldset">
          <legend class="k-form-legend">{{'REGISTRATION.subtitle' | translate}}</legend>
          <div class="row">
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="firstName" text="{{'REGISTRATION.fieldFirstName.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="firstName"
                #firstName
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldFirstName.hint' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="lastName" text="{{'REGISTRATION.fieldLastName.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="lastName"
                #lastName
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldLastName.hint' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label class="k-label" [for]="language" text="{{'REGISTRATION.fieldLanguage.label' | translate}}"> </kendo-label>
              <kendo-combobox formControlName="language" [data]="languagesArray" textField="text" valueField="value" placeholder="Language" #language></kendo-combobox>
              <kendo-formhint>{{'REGISTRATION.fieldLanguage.hint' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="phone" text="{{'REGISTRATION.fieldPhone.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="phone"
                #phone
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldPhone.hint' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="email" text="{{'REGISTRATION.fieldEmail.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="email"
                #email
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldEmail.label' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="emailCC" text="{{'REGISTRATION.fieldEmailCC.label' | translate}}"></kendo-label>
              <kendo-textbox
                formControlName="emailCC"
                #emailCC
                required
                [clearButton]="true"
              ></kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldEmailCC.hint' | translate}}</kendo-formhint>
              <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="password" text="{{'REGISTRATION.fieldPassword.label' | translate}}"> </kendo-label>
              <kendo-textbox
                formControlName="password"
                #password
                required
                [clearButton]="true"
                type="password"
                (keypress)="omit_special_char($event)"
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button type="button"
                    kendoButton
                    look="clear"
                    icon="eye"
                    (click)="toggleVisibilityPassword()"
                  ></button>
                </ng-template>
              </kendo-textbox>
              <kendo-formhint>
                <div *ngIf="registerForm.controls['password'].errors" class="row" style="line-height: 230%" [innerHTML]="'REGISTRATION.fieldPassword.hint' | translate"></div>
                <div *ngIf="!registerForm.controls['password'].errors" class="row" style="line-height: 230%; color:rgb(3,93,0)" [innerHTML]="'REGISTRATION.fieldPassword.hint' | translate"></div>
              </kendo-formhint>
              <kendo-formerror *ngIf="registerForm.controls['password'].errors && !registerForm.controls['password'].errors?.['required']">
                <div [innerHTML]="'REGISTRATION.fieldPassword.conditionError' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="registerForm.controls['password'].errors?.['required']">{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
              <kendo-formerror *ngIf="!registerForm.controls['password'].errors?.['required']">
                <div *ngIf="registerForm.controls['password'].errors?.['minlength'] || registerForm.controls['password'].errors?.['maxlength']" style="color:red" [innerHTML]="'REGISTRATION.fieldPassword.condition1' | translate"></div>
                <div *ngIf="!registerForm.controls['password'].errors?.['minlength'] && !registerForm.controls['password'].errors?.['maxlength']" style="color:green" [innerHTML]="'REGISTRATION.fieldPassword.condition1' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!registerForm.controls['password'].errors?.['required']">
                <div *ngIf="registerForm.controls['password'].errors?.['minSpecial']" style="color:red" [innerHTML]="'REGISTRATION.fieldPassword.condition2' | translate"></div>
                <div *ngIf="!registerForm.controls['password'].errors?.['minSpecial']" style="color:green" [innerHTML]="'REGISTRATION.fieldPassword.condition2' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!registerForm.controls['password'].errors?.['required']">
                <div *ngIf="registerForm.controls['password'].errors?.['minCapSmall']" style="color:red" [innerHTML]="'REGISTRATION.fieldPassword.condition3' | translate"></div>
                <div *ngIf="!registerForm.controls['password'].errors?.['minCapSmall']" style="color:green" [innerHTML]="'REGISTRATION.fieldPassword.condition3' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!registerForm.controls['password'].errors?.['required']">
                <div *ngIf="registerForm.controls['password'].errors?.['minNumber']" style="color:red" [innerHTML]="'REGISTRATION.fieldPassword.condition4' | translate"></div>
                <div *ngIf="!registerForm.controls['password'].errors?.['minNumber']" style="color:green" [innerHTML]="'REGISTRATION.fieldPassword.condition4' | translate"></div>
              </kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="col-6 col-md-12">
              <kendo-label [for]="repeatPassword" text="{{'REGISTRATION.fieldRepeatPassword.label' | translate}}"> </kendo-label>
              <kendo-textbox
                formControlName="repeatPassword"
                #repeatPassword
                required
                [clearButton]="true"
                type="repeatPassword"
                (keypress)="omit_special_char($event)"
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button type="button"
                    kendoButton
                    look="clear"
                    icon="eye"
                    (click)="toggleVisibilityRepeat()"
                  ></button>
                </ng-template>
              </kendo-textbox>
              <kendo-formhint>{{'REGISTRATION.fieldRepeatPassword.hint' | translate}}</kendo-formhint>
              <kendo-formerror *ngIf="registerForm.controls['repeatPassword'].errors?.['required']">{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
              <kendo-formerror *ngIf="registerForm.controls['repeatPassword'].errors?.['notEquivalent']">{{'REGISTRATION.fieldPassword.matchError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield class="col-12">
              <div class="row" style="text-align: center; background-color: #F3F3F3; border-radius: 3px; padding:5px" >
                <span (click)="privacyWindowOnClick('open')" style="text-decoration: underline; cursor:pointer; display: inline-flex; padding-right:10px; font-weight: bold; line-height: 1.1;">{{'REGISTRATION.fieldTermsConditionsCheck.label' | translate}}</span>
                <input type="checkbox" kendoCheckBox formControlName="termsConditionsCheck" #termsConditionsCheck />
              </div>
              <kendo-formerror>{{'REGISTRATION.fieldTermsConditionsCheck.mandatoryError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-buttons">
            <div class="login-button-row">
              <button kendoButton themeColor="primary" type="submit" class="button-primary">{{'REGISTRATION.submitButtonText' | translate}}</button>
            </div>
            <div class="other-buttons-row">
              <button kendoButton themeColor="secondary" fillMode="outline" class="button-link" (click)="openLoginPage()" [innerHTML]="'REGISTRATION.redirectToLoginButtonText' | translate"></button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span><b>Powered by RR Solutions</b></span><br/>
        <span>Via Brennero 9a, 39100 Bolzano</span><br/>
        <span>Brennerstrasse 9a, 39100 Bozen</span><br/>
        <span><a href="mailto:info@rrsolutions.it" target="_blank">info@rrsolutions.it</a></span> |
        <span><a href="//www.rrsolutions.it" target="_blank">www.rrsolutions.it</a></span>
        <div class="poweredby-logo">
          <img src="assets/media/images/Logox1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
<kendo-dialog title="Privacy" *ngIf="privacyWindowOpened" (close)="privacyWindowOnClick('close')"
  [width]="'90%'"
>
  <app-privacy-text [language]="currentLanguage" class="dialog-content"></app-privacy-text>
</kendo-dialog>
