import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { User } from '../shared/models/user.model';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  //configuration variables
  public columns = 0;

  public currentUser: User;

  constructor(private _router: Router, private _loginService: LoginService) {
  }

  ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this.currentUser = JSON.parse(lsLoggedUser);
      this._router.navigate(['/plans']);
    } else {
      this._loginService.logout();
    }
  }

}
