import { HttpHeaders } from '@angular/common/http';
import { LOCAL_STORAGE } from '../shared/constants';

export class HttpUtils {

	public static createHeaders(): HttpHeaders {
		const accessToken = localStorage.getItem('X-Access-Token');
    return new HttpHeaders({'X-Access-Token': (accessToken) ? accessToken : ''});
	}
}
