<kendo-grid
  [data]="gridData"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="{
    allowUnsort: sortingSettings.allowUnsort,
    mode: sortingSettings.multiple ? 'multiple' : 'single'
  }"
  [pageable]="{
    info: pagingSettings.info,
    type: pagingSettings.pagerTypes,
    pageSizes: pagingSettings.pageSizes,
    previousNext: pagingSettings.previousNext,
    position: pagingSettings.position,
    responsive: pagingSettings.responsive
  }"
  [reorderable]="!hiddenForMobile"
  [resizable]="!hiddenForMobile"
  filterable="menu"
  [columnMenu]="{ filter: true }"
  (dataStateChange)="dataStateChange($event)"
  style="margin-top: 10px !important"
>
  <ng-template kendoGridToolbarTemplate class="grid-toolbar">
    <div class="title-bar row" style="display:flex; width:100%;">
      <kendo-grid-spacer></kendo-grid-spacer>
      <div class="maintitle"><h2>{{'PLANS.pageTitle' | translate}}</h2></div>
      <kendo-grid-spacer></kendo-grid-spacer>
    </div>
    <div style="display:flex; width:100%;" class="info-text">
      {{'PLANS.pageMessage' | translate}}
    </div>
    <div class="search-bar row" style="display:block; width:100%;">
      <div class="search-item">
        <span class="search-title">
          <span class="secondary-icon k-icon k-i-search"></span>
          {{'SHARED.grid.openSearch.label' | translate}}
        </span>
        <input
          [style.width.px]="300"
          placeholder="{{'SHARED.grid.openSearch.field' | translate}}"
          kendoTextBox
          (input)="onFilter($event)"
        />
      </div>
    </div>
  </ng-template>
  <kendo-grid-column *ngIf="hiddenForMobile"
    title="{{'PLANS.grid.table.planslist' | translate}}"
    [headerClass]="'gridcolumn header'"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span class="responsive-row"><b>{{'PLANS.grid.table.tipoMarcheAeromobile' | translate}} {{dataItem.tipoMarcheAeromobile}}</b></span>
        <span class="responsive-row"><b>{{'PLANS.grid.table.partenzaLocation' | translate}}</b> {{dataItem.partenzaLocation}}</span>
        <span class="responsive-row"><b>{{'PLANS.grid.table.arrivoLocation' | translate}}:</b> {{dataItem.arrivoLocation}}</span>
        <span class="responsive-row"><b>{{'PLANS.grid.table.pilotaResponsabile' | translate}}:</b> {{dataItem.pilotaResponsabile}}</span>
        <span class="responsive-row"><b>{{'PLANS.grid.table.orarioDecollo' | translate}}:</b> {{dataItem.orarioDecollo}}</span>
        <span class="responsive-row"><b>PDF:</b>
          <button kendoButton icon="pdf" themeColor="secondary" fillMode="link" (click)="downloadPlanPdf(dataItem.id)">PDF</button>
        </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="tipoMarcheAeromobile"
    title="{{'PLANS.grid.table.tipoMarcheAeromobile' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="partenzaLocation"
    title="{{'PLANS.grid.table.partenzaLocation' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="arrivoLocation"
    title="{{'PLANS.grid.table.arrivoLocation' | translate}}"
    filter="text"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="pilotaResponsabile"
    title="{{'PLANS.grid.table.pilotaResponsabile' | translate}}"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    field="orarioDecollo"
    title="{{'PLANS.grid.table.orarioDecollo' | translate}}"
    filter="date"
    [headerClass]="'gridcolumn header'"
  >
  </kendo-grid-column>
  <kendo-grid-column *ngIf="!hiddenForMobile"
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [width]="150"
    [columnMenu]="false"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <button kendoButton icon="pdf" themeColor="primary" (click)="downloadPlanPdf(dataItem.id)">{{'PLANS.grid.table.pdfButtonLabel' | translate}}</button>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
