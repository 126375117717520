<div class="login-container" fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh;">
  <div class="login-box">
    <div class="logo-container row">
      <div>
        <img src="../../assets/media/images/logo_login.png" title="Apertura Eli-Avio-Idro Superficie Occasionale" alt="Apertura Eli-Avio-Idro Superficie Occasionale">
      </div>
    </div>
    <div class="title-container">
      <div kendoTypography variant="h5" textAlign="center" [margin]="{ top: 0, bottom: 0}" themeColor="light">{{'RESETPASSWORD.title' | translate}}</div>
    </div>
    <div class="form-container">
      <form class="k-form"  #loginForm="ngForm" (ngSubmit)="onReset()" [formGroup]="resetForm">
        <fieldset class="k-form-fieldset">
          <legend class="k-form-legend">{{'RESETPASSWORD.subtitle' | translate}}</legend>
          <div class="row">
            <kendo-formfield class="col-12">
              <kendo-label [for]="password" text="{{'RESETPASSWORD.fieldPassword.label' | translate}}"> </kendo-label>
              <kendo-textbox
                formControlName="password"
                #password
                required
                [clearButton]="true"
                type="password"
                (keypress)="omit_special_char($event)"
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button type="button"
                    kendoButton
                    look="clear"
                    icon="eye"
                    (click)="toggleVisibility()"
                  ></button>
                </ng-template>
              </kendo-textbox>
              <kendo-formhint>
                <div *ngIf="resetForm.controls['password'].errors" class="row" style="line-height: 230%" [innerHTML]="'RESETPASSWORD.fieldPassword.hint' | translate"></div>
                <div *ngIf="!resetForm.controls['password'].errors" class="row" style="line-height: 230%; color:rgb(3,93,0)" [innerHTML]="'RESETPASSWORD.fieldPassword.hint' | translate"></div>
              </kendo-formhint>
              <kendo-formerror *ngIf="resetForm.controls['password'].errors && !resetForm.controls['password'].errors?.['required']">
                <div [innerHTML]="'RESETPASSWORD.fieldPassword.conditionError' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="resetForm.controls['password'].errors?.['required']">{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
              <kendo-formerror *ngIf="!resetForm.controls['password'].errors?.['required']">
                <div *ngIf="resetForm.controls['password'].errors?.['minlength'] || resetForm.controls['password'].errors?.['maxlength']" style="color:red" [innerHTML]="'RESETPASSWORD.fieldPassword.condition1' | translate"></div>
                <div *ngIf="!resetForm.controls['password'].errors?.['minlength'] && !resetForm.controls['password'].errors?.['maxlength']" style="color:green" [innerHTML]="'RESETPASSWORD.fieldPassword.condition1' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!resetForm.controls['password'].errors?.['required']">
                <div *ngIf="resetForm.controls['password'].errors?.['minSpecial']" style="color:red" [innerHTML]="'RESETPASSWORD.fieldPassword.condition2' | translate"></div>
                <div *ngIf="!resetForm.controls['password'].errors?.['minSpecial']" style="color:green" [innerHTML]="'RESETPASSWORD.fieldPassword.condition2' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!resetForm.controls['password'].errors?.['required']">
                <div *ngIf="resetForm.controls['password'].errors?.['minCapSmall']" style="color:red" [innerHTML]="'RESETPASSWORD.fieldPassword.condition3' | translate"></div>
                <div *ngIf="!resetForm.controls['password'].errors?.['minCapSmall']" style="color:green" [innerHTML]="'RESETPASSWORD.fieldPassword.condition3' | translate"></div>
              </kendo-formerror>
              <kendo-formerror *ngIf="!resetForm.controls['password'].errors?.['required']">
                <div *ngIf="resetForm.controls['password'].errors?.['minNumber']" style="color:red" [innerHTML]="'RESETPASSWORD.fieldPassword.condition4' | translate"></div>
                <div *ngIf="!resetForm.controls['password'].errors?.['minNumber']" style="color:green" [innerHTML]="'RESETPASSWORD.fieldPassword.condition4' | translate"></div>
              </kendo-formerror>
            </kendo-formfield>
            <kendo-formfield class="col-12">
              <kendo-label [for]="repeatPassword" text="{{'RESETPASSWORD.fieldRepeatPassword.label' | translate}}"> </kendo-label>
              <kendo-textbox
                formControlName="repeatPassword"
                #repeatPassword
                required
                [clearButton]="true"
                type="repeatPassword"
              >
                <ng-template kendoTextBoxSuffixTemplate>
                  <button type="button"
                    kendoButton
                    look="clear"
                    icon="eye"
                    (click)="toggleVisibilityRepeat()"
                  ></button>
                </ng-template>
              </kendo-textbox>
              <kendo-formhint>{{'RESETPASSWORD.fieldRepeatPassword.hint' | translate}}</kendo-formhint>
              <kendo-formerror *ngIf="resetForm.controls['repeatPassword'].errors?.['required']">{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
              <kendo-formerror *ngIf="resetForm.controls['repeatPassword'].errors?.['notEquivalent']">{{'RESETPASSWORD.fieldPassword.matchError' | translate}}</kendo-formerror>
            </kendo-formfield>
          </div>
          <div class="form-buttons">
            <div class="login-button-row">
              <button kendoButton themeColor="primary" type="submit" class="button-primary">{{'RESETPASSWORD.submitButtonText' | translate}}</button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span><b>Powered by RR Solutions</b></span><br/>
        <span>Via Brennero 9a, 39100 Bolzano</span><br/>
        <span>Brennerstrasse 9a, 39100 Bozen</span><br/>
        <span><a href="mailto:info@rrsolutions.it" target="_blank">info@rrsolutions.it</a></span> |
        <span><a href="//www.rrsolutions.it" target="_blank">www.rrsolutions.it</a></span>
        <div class="poweredby-logo">
          <img src="assets/media/images/Logox1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
