import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Plan } from 'src/app/shared/models/plan.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_PLANS = '/plans/get',
  DOWNLOAD_PLAN = '/plans/download'
}

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  constructor(private _http: HttpClient) { }


  public getPlans(): Observable<Plan[]> {
    return this._http.get<Plan[]>(environment.serverUrl + EndpointRoutes.GET_PLANS, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public downloadPlan(planId: number): Observable<any> {
    return this._http.post(environment.serverUrl + EndpointRoutes.DOWNLOAD_PLAN, {id: planId}, {
      responseType: 'blob',
      headers: HttpUtils.createHeaders()
    });
  }
}
