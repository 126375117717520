<div class="login-container" fxLayout="column" fxLayoutAlign="center center" style="min-height: 100vh;">
  <div class="login-box">
    <div class="logo-container row">
      <div>
        <span class="logo-title">{{'SHARED.mainTitle' | translate}}</span>
      </div>
    </div>
    <div class="title-container">
      <div kendoTypography variant="h5" textAlign="center" [margin]="{ top: 0, bottom: 0}" themeColor="light">{{'ACTIVATION.title' | translate}}</div>
    </div>
    <div class="form-container user-activation-container">
      <div class="subtitle">{{'ACTIVATION.successTitle' | translate}}</div>
      <div class="text">
        <p>{{'ACTIVATION.successText' | translate}}</p>
        <p>{{'ACTIVATION.successText2' | translate}}</p>
      </div>
      <div class="form-buttons">
        <div class="login-button-row">
          <button kendoButton themeColor="primary" type="button" class="button-primary" (click)="openLoginPage()">{{'ACTIVATION.loginBtn' | translate}}</button>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div kendoTypography variant="p" fontSize="sm" textAlign="center" class="poweredby-footer">
        <span><b>Powered by RR Solutions</b></span><br/>
        <span>Via Brennero 9a, 39100 Bolzano</span><br/>
        <span>Brennerstrasse 9a, 39100 Bozen</span><br/>
        <span><a href="mailto:info@rrsolutions.it" target="_blank">info@rrsolutions.it</a></span> |
        <span><a href="//www.rrsolutions.it" target="_blank">www.rrsolutions.it</a></span>
        <div class="poweredby-logo">
          <img src="assets/media/images/Logox1.png" />
        </div>
      </div>
    </div>
  </div>
</div>
