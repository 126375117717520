<div class="main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="container" style="width: 90vw; min-height: 80vh">
    <div class="title-bar row" style="display: flex; width: 100%">
      <div class="maintitle">
        <h2 *ngIf="paymentStatus === 'success'">
          {{ "PAYRESULT.success.pageTitle" | translate }}
        </h2>
        <h2 *ngIf="paymentStatus === 'cancelled'">
          {{ "PAYRESULT.cancelled.pageTitle" | translate }}
        </h2>
        <h2 *ngIf="paymentStatus === 'failed'">
          {{ "PAYRESULT.failed.pageTitle" | translate }}
        </h2>
      </div>
    </div>
    <div class="info-text">
      <!-- SUCCESS TEXT-->
      <ng-container *ngIf="paymentStatus === 'success'">
        <ng-container *ngIf="locale === 'it'">
          <p>Gentile cliente,</p>
          <p>
            <span><b>La ringraziamo molto per il Suo acquisto!</b></span>
          </p>
          <p>
            Confermiamo di aver ricevuto il pagamento per l'acquisto del
            pacchetto da Lei scelto. A breve dovrebbe ricevere sul suo indirizzo
            email la conferma del pagamento da parte di Paypal. La fattura verrà
            emessa entro 30 giorni e verrà inviata sempre per email. La fattura
            verrà inviata anche sul sistema di interscambio (SDI) ma solo se ha
            inserito il codice nell'apposito campo SDI nei dati di fatturazione.
          </p>
          <p>
            Qui di seguito i dati relativi al suo nuovo contratto di abbonamento
            con Apertura Elisuperficie Occasionale.
          </p>
          <p>
            Per caricare il nuovo abbonamento nell'app sarà sufficiente
            cliccare sul pulsante di refresh oppure effettuare un logout-login.
          </p>
          <p>Distinti Saluti.</p>
          <p>
            Il team di Apertura Elisuperficie Occasionale &copy; - RR Solutions
            sas.
          </p>
        </ng-container>
        <ng-container *ngIf="locale === 'de'">
          <p>Sehr geehrter Kunde,</p>
          <p>
            <span
              ><b
                >Herzlichen Dank, dass Sie sich für unsere App entschieden
                haben!</b
              ></span
            >
          </p>
          <p>
            Wir bestätigen, dass wir die Zahlung das von Ihnen gewählten Paket
            erhalten haben. Sie sollten in Kürze die Zahlungsbestätigung von
            Paypal per E-Mail erhalten. Wir werden die Rechnung wird innerhalb
            von 30 Tagen ausstellen und ebenfallst per E-Mail zustellen. Die
            Rechnung wird auch über das automatische Rechnungssystem SDI
            versandt, aber Sie das Feld SDI in den Rechnungsdaten ausgefüllt
            haben.
          </p>
          <p>
            Es folgen die Einzelheiten Ihres neuen Abonnementvertrags mit
            Apertura Elisuperficie Occasionale.
          </p>
          <p>
            Um die Daten in der App sofort zu übernehmen, clicken Sie die Schaltfläche oder loggen Sie sich
            einfach aus und wieder ein.
          </p>
          <p>Mit freundlichen Grüßen.</p>
          <p>
            Das Team von Apertura Elisuperficie Occasionale &copy; - RR
            Solutions sas.
          </p>
        </ng-container>
        <ng-container *ngIf="locale === 'en'">
          <p>Dear Customer,</p>
          <p>
            <span><b>Thank you very much for your purchase!</b></span>
          </p>
          <p>
            We confirm that we have received payment for the purchase of your
            selected service package. You should shortly receive the payment
            confirmation from Paypal on your email address. The invoice will be
            issued within 30 days and will always be sent by email. The invoice
            will also be sent on the interchange system (SDI), but only if you
            have entered the code in the SDI field in the invoice data.
          </p>
          <p>
            Below are the details of your new subscription contract with
            Apertura Elisuperficie Occasionale.
          </p>
          <p>To load the new subscription into the app, simply click the refresh button or do logout/login.</p>
          <p>Best regards.</p>
          <p>
            The Apertura Elisuperficie Occasionale &copy; team - RR Solutions
            sas.
          </p>
        </ng-container>
      </ng-container>
      <!-- FAILED OR CANCELLED TEXT-->
      <ng-container *ngIf="paymentStatus !== 'success'">
        <ng-container *ngIf="locale === 'it'">
          <p>Gentile cliente,</p>
          <p>
            <span
              ><b
                >Siamo spiacenti ma l'acquisto del pacchetto non è andato a buon
                fine!</b
              ></span
            >
          </p>
          <p>
            Il motivo potrebbe essere un annullamento dell'ordine da parte del
            cliente, l'utilizzo di una carta senza il sufficiente credito oppure
            semplicemente ha inserito i dati della carta non corretti. Può anche
            essere che il Suo account PayPal risulti bloccato.
          </p>
          <p>
            Se vi è stato un errore da parte Sua o un disservizio alla Sua
            connessione internet la invitiamo a riprovare con l'acquisto più
            tardi.
          </p>
          <p>
            Se invece sta riscontrando delle difficoltà nel pagamento oppure in
            qualsiasi altra fase del processo di acquisto, la preghiamo
            gentilmente di contattarci scrivendo a
            <a href="mailto:info@rrsolutions.it">info@rrsolutions.it</a>.
          </p>
          <p>Speriamo di averLa presto come nostro cliente.</p>
          <p>Distinti Saluti.</p>
          <p>
            Il team di Apertura Elisuperficie Occasionale &copy; - RR Solutions
            sas.
          </p>
        </ng-container>
        <ng-container *ngIf="locale === 'de'">
          <p>Sehr geehrter Kunde,</p>
          <p>
            <span
              ><b
                >Es tut uns leid, aber die Zahlung unserer Serviceleistung war
                nicht erfolgreich!</b
              ></span
            >
          </p>
          <p>
            Der Grund dafür könnte sein, dass Sie die Bestellung storniert
            haben, die Karte nicht über ausreichendes Guthaben verfügt oder
            einfach falsche Kartendaten eingegeben hat. Es kann auch sein, dass
            Ihr PayPal-Konto gesperrt ist.
          </p>
          <p>
            Sollte ein Fehler Ihrerseits oder eine Störung Ihrer
            Internetverbindung vorliegen, versuchen Sie es bitte später noch
            einmal.
          </p>
          <p>
            Wenn Sie Schwierigkeiten mit der Zahlung oder einem anderen Schritt
            im Kaufprozess haben, kontaktieren Sie uns bitte unter
            <a href="mailto:info@rrsolutions.it">info@rrsolutions.it</a>.
          </p>
          <p>Wir freuen uns, Sie bald zu unseren Kunden zählen zu können.</p>
          <p>Mit freundlichen Grüßen.</p>
          <p>
            Das Team Apertura Elisupterficie Occasionale &copy; - RR Solutions
            sas.
          </p>
        </ng-container>
        <ng-container *ngIf="locale === 'en'">
          <p>Dear Customer,</p>
          <p>
            <span
              ><b
                >We are sorry, but the purchase of the service package was not
                successful!</b
              ></span
            >
          </p>
          <p>
            The reason could be that you cancelled your order, used a card
            without enough credit or simply entered incorrect card details. It
            may also be that your PayPal account is blocked.
          </p>
          <p>
            If there has been an error on your part or a malfunction in your
            Internet connection, please try again with your purchase later.
          </p>
          <p>
            If you are experiencing difficulties with payment or any other step
            in the purchasing process, please contact us at
            <a href="mailto:info@rrsolutions.it">info@rrsolutions.it</a>.
          </p>
          <p>Best Regards</p>
          <p>
            The Apertura Elisuperficie Occasionale &copy; Team - RR Solutions
            sas.
          </p>
        </ng-container>
      </ng-container>
    </div>
    <div class="pricing-table" *ngIf="paymentStatus === 'success'">
      <div class="container row">
        <div class="price-box">
          <div class="card-body row">
            <div class="card-text">
              {{ "ACCOUNT.grid.subscriptionInfos.daysLeft" | translate }}
            </div>
            <div class="card-value">{{ daysLeft }}</div>
          </div>
        </div>
        <div class="price-box">
          <div class="card-body row">
            <div class="card-text">
              {{ "ACCOUNT.grid.subscriptionInfos.expirationDate" | translate }}
            </div>
            <div class="card-value">{{ expirationDateFormat }}</div>
          </div>
        </div>
        <div class="price-box">
          <div class="card-body row">
            <div class="card-text">
              {{ "ACCOUNT.grid.subscriptionInfos.pilotsMax" | translate }}
            </div>
            <div class="card-value">
              {{
                userSubscription.subscription.pilots < 0
                  ? ("ACCOUNT.grid.subscriptionInfos.unlimitedLabelM"
                    | translate)
                  : userSubscription.subscription.pilots
              }}
            </div>
          </div>
        </div>
        <div class="price-box">
          <div class="card-body row">
            <div class="card-text">
              {{ "ACCOUNT.grid.subscriptionInfos.aircraftsMax" | translate }}
            </div>
            <div class="card-value">
              {{
                userSubscription.subscription.helicopters < 0
                  ? ("ACCOUNT.grid.subscriptionInfos.unlimitedLabelM"
                    | translate)
                  : userSubscription.subscription.helicopters
              }}
            </div>
          </div>
        </div>
        <div class="price-box">
          <div class="card-body row">
            <div class="card-text">
              {{ "ACCOUNT.grid.subscriptionInfos.plansMax" | translate }}
            </div>
            <div class="card-value">
              {{
                userSubscription.subscription.plans < 0
                  ? ("ACCOUNT.grid.subscriptionInfos.unlimitedLabelF"
                    | translate)
                  : userSubscription.subscription.plans
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
