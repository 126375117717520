<kendo-dialog
    *ngIf="active"
    [width]="'80%'"
    [maxWidth]="400"
    [height]="'80%'"
    (close)="closeForm()"
>
    <kendo-dialog-titlebar>
      {{ isNew ? ('PILOTS.grid.form.insertTitle' | translate) :  ('PILOTS.grid.form.updateTitle' | translate) }}
    </kendo-dialog-titlebar>
    <form novalidate class="k-form" [formGroup]="editForm" *ngIf="!blockInsertError && !subscriptionExpiredError">
      <kendo-formfield>
          <kendo-label class="label" [for]="firstName" text="{{'PILOTS.grid.form.fieldFirstName.label' | translate}}"></kendo-label>
          <kendo-textbox
          formControlName="firstName"
          #firstName
          required
          ></kendo-textbox>
          <kendo-formhint>{{'PILOTS.grid.form.fieldFirstName.hint' | translate}}</kendo-formhint>
          <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label class="label" [for]="lastName" text="{{'PILOTS.grid.form.fieldLastName.label' | translate}}"></kendo-label>
          <kendo-textbox
              formControlName="lastName"
              #lastName
              required
          ></kendo-textbox>
          <kendo-formhint>{{'PILOTS.grid.form.fieldLastName.hint' | translate}}</kendo-formhint>
          <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label class="label" [for]="licenseNumber" text="{{'PILOTS.grid.form.fieldLicenseNumber.label' | translate}}"></kendo-label>
          <kendo-textbox
              formControlName="licenseNumber"
              #licenseNumber
              required
          ></kendo-textbox>
          <kendo-formhint>{{'PILOTS.grid.form.fieldLicenseNumber.hint' | translate}}</kendo-formhint>
          <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
      </kendo-formfield>
      <kendo-formfield>
          <kendo-label class="label" [for]="phone" text="{{'PILOTS.grid.form.fieldPhone.label' | translate}}"></kendo-label>
          <kendo-textbox
              formControlName="phone"
              #phone
              required
          ></kendo-textbox>
          <kendo-formhint>{{'PILOTS.grid.form.fieldPhone.hint' | translate}}</kendo-formhint>
          <kendo-formerror>{{'SHARED.form.mandatoryError' | translate}}</kendo-formerror>
      </kendo-formfield>
    </form>
    <ng-container *ngIf="blockInsertError && !subscriptionExpiredError" class="error-template">
      <div class="block-insert info-text">
        <span class="info-title">{{'SHARED.alerts.maxPilot.partOne' | translate}}</span><br/>
        {{'SHARED.alerts.maxPilot.partTwo' | translate}} <br />
        {{'SHARED.alerts.maxPilot.partThree' | translate}}  <br />
        <button class="subscriptions-button" kendoButton themeColor="secondary" fillMode="solid" (click)="navigateSubscriptions()">{{'SHARED.alerts.maxPilot.buttonSubscription' | translate}}</button>
      </div>
    </ng-container>
    <ng-container *ngIf="subscriptionExpiredError" class="error-template">
      <div class="block-insert info-text">
        <span class="info-title">{{'SHARED.alerts.subscriptionExpired.partOneTwo' | translate}}</span> <br />
        {{'SHARED.alerts.subscriptionExpired.partThree' | translate}} <br />
        {{'SHARED.alerts.subscriptionExpired.partFour' | translate}} <br />
        <button class="subscriptions-button" kendoButton themeColor="secondary" fillMode="solid" (click)="navigateSubscriptions()">{{'SHARED.alerts.subscriptionExpired.buttonSubscription' | translate}} </button>
      </div>
    </ng-container>
    <kendo-dialog-actions *ngIf="!subscriptionExpiredError && !blockInsertError">
    <button kendoButton (click)="onCancel($event)" icon="reset">{{'SHARED.form.reset' | translate}}</button>
    <button
        kendoButton
        themeColor="secondary"
        [disabled]="!editForm.valid || blockInsertError || subscriptionExpiredError"
        (click)="onSave($event)"
        icon="save"
    >
    {{'SHARED.form.save' | translate}}
    </button>
    </kendo-dialog-actions>
</kendo-dialog>
