import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-footer',
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss']
})
export class MainFooterComponent implements OnInit {
  public year = new Date().getFullYear();
  public privacyWindowOpened = false;
  public currentLanguage: string  = 'it'

  constructor(private _translateService: TranslateService) { }

  ngOnInit(): void {
      this.currentLanguage = this._translateService.currentLang;
  }


  public privacyWindowOnClick(action: string): void {
    switch (action.toLowerCase()) {
      case 'open':
        this.privacyWindowOpened = true;
        break;
      case 'close':
        this.privacyWindowOpened = false;
        break;
    }
  }

}
