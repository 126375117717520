import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'src/app/shared/models/subscription';
import { User } from 'src/app/shared/models/user.model';
import { SharedService } from 'src/app/shared/shared.service';
import { PilotsService } from '../pilots/pilots.service';
import { SubscriptionsService } from './subscriptions.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit{

  public subscriptionsArray: Subscription[] = [];
  public choosenSubscription: string;
  public user: User;
  public currentUserSubscriptionId: number;
  public isTrial = false;
  public isExpired = false;
  public currentPilotsCount: number = 0;
  private _userSubscription: any;

  constructor(private _subscriptionsService: SubscriptionsService, private _translate: TranslateService,
    private _sharedService: SharedService, private _router: Router, private _pilotsService: PilotsService) { }


    ngOnInit() {
      this._userSubscription = this._sharedService.getSubscriptionTokenObject();
      this.currentUserSubscriptionId = this._userSubscription.subscription.id;
      this.isTrial = this._userSubscription.isTrial;
      this._pilotsService.getPilots().subscribe((result) => {
        this.currentPilotsCount = result.length;
      });
      const dayDiff = moment.duration(moment(this._userSubscription.to).diff(moment().format('YYYY-MM-DD')));
      if (dayDiff.asDays() < 0) {
        this.isExpired = true;
      }
      this._subscriptionsService.getSubscriptions().subscribe({
        next:(result)=> {
          this.subscriptionsArray = result;
        },
        error: (error) => {}
      });
    }

    getUpdateSubscriptionDecision(updateSubscription: boolean) {
      if (updateSubscription) {
        this._subscriptionsService.updateUserSubscription(this.choosenSubscription).subscribe(result => {
          this._subscriptionsService.getSubscriptions().subscribe(array => {
            this.subscriptionsArray = [];
            this.subscriptionsArray = array;
          });
        });
      }
    }

    checkOut(subscriptionId: number) {
      localStorage.setItem('subscriptionId',subscriptionId.toString());
      this._router.navigate(['/subscriptions/cart']);
    }

}
