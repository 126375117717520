import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataBindingDirective, DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { process, State } from '@progress/kendo-data-query';
import { saveAs } from '@progress/kendo-file-saver';
import { IconsModule } from '@progress/kendo-angular-icons';
import { Plan } from 'src/app/shared/models/plan.model';
import { User } from 'src/app/shared/models/user.model';
import { PlansService } from './plans.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public plansDataArray: Plan[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _currentUser: User;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings = {
    multiple: true,
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    sort: [{ 'field': 'orarioDecollo', 'dir': 'desc' }]
    // Initial filter descriptor
  };

  public showHeader = false;

  constructor(private _plansService: PlansService, private _sharedService: SharedService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
        this.pagingSettings.pageSize = 5;
        this.state.take = 5;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
    }
    this._setGridData();
  }

  private _setGridData() {
    this._plansService.getPlans().subscribe((result) => {
      this.plansDataArray = result;
      this.gridData = process(result, this.state);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    if (state && state.group) {
      // state.group.map((group) => (group.aggregates = this.aggregates));
    }
    this.state = state;
    this.gridData = process(this.plansDataArray, this.state);
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "tipoMarcheAeromobile",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "partenzaLocation",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "arrivoLocation",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "pilotaResponsabile",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "orarioDecollo",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.plansDataArray, this.state);
  }

  public downloadPlanPdf(planId: number) {
    this._plansService.downloadPlan(planId).subscribe(result => {
      saveAs(result, 'plan_' + planId + '.pdf');
    });
  }
}
