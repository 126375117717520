<div class="main-content" fxLayout="column" fxLayoutAlign="top center">
  <div class="container" style="width:90vw; min-height:80vh;">
      <div class="title-bar row" style="display:flex; width:100%;">
          <div class="maintitle"><h2>{{'SUBSCRIPTIONS.pageTitle' | translate}}</h2></div>
      </div>
      <div style="width:100%;" class="info-text">
        <span>NOTE:</span> {{'PILOTS.pilotsInfo' | translate}}
      </div>
      <div class="pricing-table">
          <div class="container row">
              <div class="row">
                <ng-container *ngFor="let subscription of subscriptionsArray">
                  <div class="price-box {{subscription.id === currentUserSubscriptionId ? 'current' : ''}}" *ngIf="subscription.id !== 1">
                      <div class="card-body row" [ngClass]="{'basic': subscription.id === 2, 'bronze': subscription.id === 3, 'silver': subscription.id === 4, 'gold': subscription.id === 5 }">
                          <div class="card-text">
                            <div class="flag" *ngIf="subscription.id === 2"><span class="text">basic</span></div>
                            <div class="flag" *ngIf="subscription.id === 3"><span class="text">bronze</span></div>
                            <div class="flag" *ngIf="subscription.id === 4"><span class="text">silver</span></div>
                            <div class="flag" *ngIf="subscription.id === 5"><span class="text">gold</span></div>
                            {{subscription.name}}
                          </div>
                          <div class="card-value">
                            <div class="desc">{{'SUBSCRIPTIONS.daysLabel' | translate}}: <span>{{subscription.subscriptionType.days}} {{'SUBSCRIPTIONS.days' | translate}}</span></div>
                            <div class="desc">{{'SUBSCRIPTIONS.pilots' | translate}}: <span>{{(subscription.pilots < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.pilots.toString()) | translate}}</span></div>
                            <div class="desc">{{'SUBSCRIPTIONS.aircrafts' | translate}}: <span>{{(subscription.helicopters < 0 ? 'SUBSCRIPTIONS.unlimitedM' : subscription.helicopters.toString()) | translate}}</span></div>
                            <div class="desc">{{'SUBSCRIPTIONS.plans' | translate}}: <span>{{(subscription.plans < 0 ? 'SUBSCRIPTIONS.unlimitedF' : subscription.plans.toString()) | translate}}</span></div>
                            <div class="price">{{(subscription.price / 1.22) | currencyFormat:'€':3:2:'.':','}}</div>
                            <div class="vat">({{'SUBSCRIPTIONS.vatExcluded' | translate}})</div>
                            <ng-container *ngIf="!isExpired && subscription.id == currentUserSubscriptionId; else buttonTemplate">
                                <div class="message-current" role="alert">{{'SUBSCRIPTIONS.currentRunning' | translate}}</div>
                            </ng-container>
                            <ng-template #buttonTemplate>
                              <button class="checkout-button" kendoButton themeColor="primary" fillMode="solid" (click)="checkOut(subscription.id)"
                                [hidden]="(subscription.pilots < currentPilotsCount) && (subscription.pilots >= 0)">
                                {{'SUBSCRIPTIONS.updateYourPackage' | translate}}</button>
                            </ng-template>
                          </div>
                      </div>
                  </div>
                </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>
