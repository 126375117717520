import { Injectable } from '@angular/core';
import * as crypto from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {

  private _secretKey = 'I&D^ReH2j(*U#f4sd*&HTy2s7t1g*(vU';
  private _iv: string = '3W_(*kj8E1w8Pd2i';
  private _wordArray = crypto.lib.WordArray;

  constructor() { }

  decryptData(txt: string) {
    const key = crypto.enc.Utf8.parse(this._secretKey);
    const iv = crypto.enc.Utf8.parse(this._iv);
    // const txtDecoded = crypto.enc.Base64.parse(txt);
    const decrypted = crypto.AES.decrypt(txt, key,
      { iv:
          iv,
          keySize: 32,
          mode: crypto.mode.CBC,
          padding: crypto.pad.Pkcs7,
      }
    )
    return decrypted.toString(crypto.enc.Utf8)
  }

}
