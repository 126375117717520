import { User } from "./user.model";

export class Plan {
    public id: number;
    public direzioneAeroportualeDi: string;
    public ATS: number;
    public ATSEmail: string;
    public partenza: number;
    public partenzaLocation: string;
    public arrivo: number;
    public arrivoNorth: string;
    public arrivoEast: string;
    public arrivoLocation: string;
    public tipoMarcheAeromobile: string;
    public orarioDecollo: string;
    public orarioAtterraggio: string;
    public autonomiaAeromobile: string;
    public activityDays: number;
    public numeroMovimenti: number;
    public pilotaResponsabile: string;
    public personeTrasportate: number;
    public activityType: string;
    public ownerConsent: string;
    public sicurezza: string;
    public carabinieriLocation: string;
    public carabinieriEmail: string;
    public createdAt: string;
    public cancelledAt: string;
    public sentEmailResponse: string;
    public cancelledEmailResponse: string;
    public useCompany: boolean;
    public pdfName: string;
    public pdfAddress: string;
    public pdfPostCode: string;
    public pdfCity: string;
    public pdfEmail: string;
    public pdfPEC: string;
    public pdfPhone: string;
    public pdfFax: string;
    public user: User;
}
