<div class="grid-toolbar">
  <form [formGroup]="searchForm" class="search-bar row">
    <div class="search-item">
      <span class="search-title">
        <span class="secondary-icon k-icon k-i-search"></span>
        {{ 'SHARED.grid.openSearch.label' | translate }}
      </span>
      <input
        class="k-textbox k-input k-input-md k-rounded-md k-input-solid"
        formControlName="searchValue"
        placeholder="nome,data,targa..."
        kendoTextBox
        (input)="getMarkersBySearch($event)"
      />
      <div class="search-results">
        <span class="results-text">Results:</span>
        <span class="results-number">{{ markers.length }}</span>
      </div>
    </div>
  </form>
  <kendo-daterange>
    <kendo-floatinglabel text="From">
      <kendo-dateinput
        kendoDateRangeStartInput
        [(value)]="range.start"
        [format]="dateFormat"
      ></kendo-dateinput>
    </kendo-floatinglabel>
    <kendo-floatinglabel text="To">
      <kendo-dateinput
        kendoDateRangeEndInput
        [(value)]="range.end"
        [format]="dateFormat"
      ></kendo-dateinput>
    </kendo-floatinglabel>
  </kendo-daterange>
  <button kendoButton (click)="getMarkersByDate()" class="k-button k-primary">Search by Date</button>
</div>
<br>
<google-map
  #googleMap
  height="100%"
  width="100%"
  [zoom]="13"
  [options]="options"
>
  <map-marker-clusterer
    #markerCluster
    [maxZoom]="10"
    [averageCenter]="true"
    imagePath="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    (clusterClick)="handleClusterClick()"
  >
    <map-marker
      *ngFor="let markerData of markers"
      [position]="markerData.marker.getPosition()!"
      (mapClick)="openInfo($event, markerData)"
    ></map-marker>
    <map-info-window
      [options]="infoWindowOptions"
      (closeclick)="closeInfo()"
    >
    </map-info-window>
  </map-marker-clusterer>

</google-map>
