import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Subscription } from 'src/app/shared/models/subscription';
import { Invoice } from 'src/app/shared/models/invoice';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionsService {

	constructor(private http: HttpClient) {
	}

	public getSubscriptions(): Observable<Subscription[]> {
		return this.http.get<Subscription[]>(environment.serverUrl + '/subscriptions/get', { headers: HttpUtils.createHeaders() }).pipe(
			map(responseData => {
        responseData.forEach(rec=>{
          rec.VATPerc = 22;
          rec.VATAmount = rec.price * rec.VATPerc / 122;
          rec.netPrice = rec.price - rec.VATAmount;
        });
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
		);
	}

	public getSubscriptionById(subscriptionId: any): Observable<Subscription> {
		return this.http.get<Subscription>(environment.serverUrl + '/subscriptions/get',
			{ params: { id: subscriptionId }, headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          responseData.VATPerc = 22;
          responseData.VATAmount = responseData.price * responseData.VATPerc / 122;
          responseData.netPrice = responseData.price - responseData.VATAmount;
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}

  public getUserSubscription(): Observable<any> {
    console.log('accessToken:',HttpUtils.createHeaders());
    return this.http.get<any>(environment.serverUrl + '/userSubscription/get', { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

	public updateUserSubscription(subscriptionId: string): Observable<Subscription> {
		return this.http.put<Subscription>(environment.serverUrl + '/userSubscription/update', { subscriptionId }, { headers: HttpUtils.createHeaders() }).pipe(
			map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
		);
	}


	public assignUserSubscription(rc: string, transactionId: string): Observable<any> {
		return this.http.put(environment.serverUrl + '/userSubscription/assign',
			{ rc, transactionId }, { headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}

	public getInvoiceDataOfUser(): Observable<Invoice> {
		return this.http.get<Invoice>(environment.serverUrl + '/user/invoice',
			{ params: {}, headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}

  public invoiceSave(subscription: Subscription, invoice: Invoice): Observable<any> {
		return this.http.put(environment.serverUrl + '/invoice/save',
			{ subscription, invoice }, { headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}


  public invoiceUpdate(rc: string, description: string): Observable<any> {
		return this.http.post(environment.serverUrl + '/invoice/update',
			{ rc, description }, { headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}

  public getUserInvoiceByRequestCode(requestCode: string): Observable<any> {
		return this.http.get<any>(environment.serverUrl + '/user/invoice',
			{ params: {rc: requestCode}, headers: HttpUtils.createHeaders() }).pipe(
				map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(() => errorRes);
        })
			);
	}
}
