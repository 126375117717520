import { LOCALE_ID, NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialDesignModule } from './material-design.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { AppLayoutComponent } from './app-layout/app-layout.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MainFooterComponent } from './app-layout/main-footer/main-footer.component';
import { MainToolbarComponent } from './app-layout/main-toolbar/main-toolbar.component';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessagesService } from './shared/kendo-messages.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { TypographyModule } from '@progress/kendo-angular-typography';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { RegisterComponent } from './register/register.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { LOCAL_STORAGE } from './shared/constants';
import { PlansComponent } from './modules/plans/plans.component';
import { PilotsComponent } from './modules/pilots/pilots.component';
import { AircraftsComponent } from './modules/aircrafts/aircrafts.component';
import { AccountComponent } from './modules/account/account.component';
import { PilotsEditDialogComponent } from './modules/pilots/pilots-edit-dialog/pilots-edit-dialog.component';
import { AircraftsEditDialogComponent } from './modules/aircrafts/aircrafts-edit-dialog/aircrafts-edit-dialog.component';
import { JwtModule } from '@auth0/angular-jwt';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { SubscriptionsComponent } from './modules/subscriptions/subscriptions.component';
import { CartComponent } from './modules/subscriptions/cart/cart.component';
import { CurrencyFormatPipe } from './shared/pipes/currency-format.pipe';
import { NgxPayPalModule } from 'ngx-paypal';
import { CartPaymentPaypalComponent } from './modules/subscriptions/cart-payment-paypal/cart-payment-paypal.component';
import { CartResultPaypalComponent } from './modules/subscriptions/cart-result-paypal/cart-result-paypal.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { PopupModule } from '@progress/kendo-angular-popup';
import { PrivacyTextComponent } from './shared/privacy-text/privacy-text.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserActivationComponent } from './user-activation/user-activation.component';
import { GoogleMapsModule, MapMarkerClusterer } from '@angular/google-maps'
import { PlansMapComponent } from "./modules/map/plans-map.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";

export function tokenGetter() {
  return localStorage.getItem(LOCAL_STORAGE.AAESO_TOKEN);
}

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    LoadingSpinnerComponent,
    AppLayoutComponent,
    LoginComponent,
    MainComponent,
    MainFooterComponent,
    MainToolbarComponent,
    RegisterComponent,
    RecoverPasswordComponent,
    PlansComponent,
    PilotsComponent,
    AircraftsComponent,
    AccountComponent,
    PlansMapComponent,
    PilotsEditDialogComponent,
    AircraftsEditDialogComponent,
    SubscriptionsComponent,
    CartComponent,
    CurrencyFormatPipe,
    CartPaymentPaypalComponent,
    CartResultPaypalComponent,
    PrivacyTextComponent,
    ResetPasswordComponent,
    UserActivationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GridModule,
    BrowserAnimationsModule,
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter, headerName: 'X-Access-Token' } }),
    FormsModule,
    ReactiveFormsModule,
    MaterialDesignModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    IndicatorsModule,
    TypographyModule,
    InputsModule,
    LabelModule,
    FlexLayoutModule,
    ButtonsModule,
    DialogsModule,
    NotificationModule,
    LayoutModule,
    DropDownsModule,
    NgxPayPalModule,
    TooltipsModule,
    PopupModule,
    GoogleMapsModule,
    DateInputsModule,
  ],
  providers: [
    { provide: MessageService, useClass: KendoMessagesService },
    { provide: LOCALE_ID, useValue: 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
