import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './recover-password.service';
import { NotificationService } from "@progress/kendo-angular-notification";

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent {
  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;
  public errorArray = new Array();
  public recoveryForm: FormGroup;
  public errorMessage!: String;

  public submitButtonDisabled: boolean = false;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _loginService: LoginService,
    private _loadingService: LoadingService, private _translate: TranslateService, private notificationService: NotificationService) {
    this.recoveryForm = this._formBuilder.group({
      'email': ['', <any>Validators.required]
    });
  }

  public onRecovery(): void {
    this.submitButtonDisabled = true;
    this.recoveryForm.markAllAsTouched();
    const usernameControl = this.recoveryForm.get('email');
    if (usernameControl) {
      if (this.recoveryForm.valid) {
        usernameControl.setErrors(null);
        this._loginService.resetPassword(usernameControl.value).subscribe({
          next: () => {
            this.notificationService.show({
              content: this._translate.instant('PASSWORDRECOVERY.doneMessage'),
              cssClass: "button-notification",
              animation: { type: "slide", duration: 500 },
              position: { horizontal: "center", vertical: "bottom" },
              type: { style: "success", icon: true },
              hideAfter: 5000,
            }).afterHide?.subscribe(()=> {
              this._router.navigate(['/login']);
            });
          },
          error: (error: { status: number; }) => {
            if (error.status === 601) {
              this.errorArray['email'] = this._translate.instant('PASSWORDRECOVERY.emailNotFoundError');
              this.notificationService.show({
                content: this._translate.instant('PASSWORDRECOVERY.emailNotFoundError'),
                cssClass: "button-notification",
                animation: { type: "slide", duration: 500 },
                position: { horizontal: "center", vertical: "bottom" },
                type: { style: "error", icon: true },
                hideAfter: 5000,
              }).afterHide?.subscribe(()=> {
                this._router.navigate(['/register']);
              });;
            }
          }
        });

      }
    }
  }

  public clearForm(): void {
    this.recoveryForm.reset();
  }

  public openLoginPage(): void {
    this._router.navigate(['/login']).then(() => {
      this._loadingService.setHttpProgressStatus(false);
    });
  }
}
