import { User } from "./user.model";

export class Aircraft {
    public id: number;
    public name: string;
    public type: string;
    public status: number;
    public createdAt: string;
    public updatedAt: string;
    public user: User;
}
