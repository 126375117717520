import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Validators, FormGroup, FormControl } from "@angular/forms";
import { Aircraft } from "src/app/shared/models/aircraft.model";

@Component({
  selector: 'aircrafts-edit-dialog',
  templateUrl: './aircrafts-edit-dialog.component.html',
  styleUrls: ['./aircrafts-edit-dialog.component.scss']
})

export class AircraftsEditDialogComponent {
  public active = false;
  public editForm: FormGroup = new FormGroup({
    id: new FormControl(null),
    name: new FormControl("", Validators.required),
    type: new FormControl("", Validators.required)
  });

  @Input() public isNew = false;
  @Input() public blockInsertError = false;
  @Input() public subscriptionExpiredError = false;

  @Input() public set model(aircraft: Aircraft | undefined) {
    this.editForm.reset(aircraft);

    // toggle the Dialog visibility
    this.active = aircraft !== undefined;
  }

  @Output() cancel: EventEmitter<undefined> = new EventEmitter();
  @Output() save: EventEmitter<Aircraft> = new EventEmitter();

  @Output() goToSubscriptions: EventEmitter<undefined> = new EventEmitter();

  public onSave(e: PointerEvent): void {
    e.preventDefault();
    this.save.emit(this.editForm.value);
    this.active = false;
  }

  public onCancel(e: PointerEvent): void {
    e.preventDefault();
    this.closeForm();
  }

  public closeForm(): void {
    this.active = false;
    this.cancel.emit();
  }

  public navigateSubscriptions() {
    this.active = false;
    this.goToSubscriptions.emit();

  }
}
