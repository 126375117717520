import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './login/login.service';
import { LocalStorageService } from './shared/local-storage.service';
import { Menu } from './shared/models/menu.model';
import { SharedService } from './shared/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  private _locale: string | null;
  outputMenus: Menu[] = [];

  title = 'aaeso-client-frontend-v2';
  constructor(public translate: TranslateService, private _localStorageService: LocalStorageService, private _loginService: LoginService, private _sharedService: SharedService) {
    this._sharedService.manageLocale();
  }
  ngOnInit(): void {
    if (this._locale) {
      this._loginService.autoLogin();
      this.outputMenus = this._sharedService.getMenus(this._locale)
    }
  }
}
