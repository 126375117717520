import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  NotificationRef,
  NotificationService,
} from '@progress/kendo-angular-notification';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { Invoice } from 'src/app/shared/models/invoice';
import { Subscription } from 'src/app/shared/models/subscription';
import { SharedService } from 'src/app/shared/shared.service';
import { SubscriptionsService } from '../subscriptions.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  public formGroup: FormGroup;
  public disablePayButton = false;
  public subscription: Subscription;
  public subscriptionId: number;

  public invoice: Invoice;

  constructor(
    private _notificationService: NotificationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _sharedService: SharedService,
    private _subscriptionsService: SubscriptionsService,
    private _formBuilder: FormBuilder,
    private _translate: TranslateService
  ) {
    this.formGroup = this._formBuilder.group({
      id: [''],
      companyName: ['', <any>Validators.required],
      vat: [''],
      fiscalCode: [''],
      address: [''],
      address2: [''],
      postalCode: [''],
      province: [''],
      city: [''],
      country: ['Italia', <any>Validators.required],
      phone: ['', <any>Validators.required],
      email: ['', [<any>Validators.email, <any>Validators.required]],
      PEC: [''],
      ISDNumber: [''],
      useDefault: [false],
    });
  }

  ngOnInit() {
    if (localStorage.getItem('subscriptionId')) {
      this.subscriptionId = parseInt(localStorage.getItem('subscriptionId') || '0');
      this._subscriptionsService
          .getSubscriptionById(this.subscriptionId)
          .subscribe((result) => {
            this.subscription = result;
            this._subscriptionsService
              .getInvoiceDataOfUser()
              .subscribe((invoiceResult) => {
                if (invoiceResult) {
                  this.formGroup.patchValue(invoiceResult);
                  this.invoice = invoiceResult;
                }
              });
          });
          return;
    } else {
      return this._router.navigate(['/packages']);
    }
  }

  public saveChanges(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      const invoice = this.formGroup.value;
      //save invoice information
      this._subscriptionsService.invoiceSave(this.subscription, invoice).subscribe({
        next:(result) => {
          const requestCode = result.requestCode;
          console.log('requestCode:', requestCode);
          const notificationDone: NotificationRef =
          this._notificationService.show({
            content: this._translate.instant('SHARED.messages.updateConfirmed'),
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'success', icon: true },
            hideAfter: 3000,
          });
          notificationDone.afterHide?.subscribe(()=> {
            this._router.navigate(['/subscriptions/payment/',requestCode]);
          });
        },
        error:(error) => {
          const notificationError: NotificationRef =
          this._notificationService.show({
            content: error.error,
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });

          notificationError.afterHide?.subscribe(()=> {
          this._router.navigate(['/packages']);
        });
        }
      });
    }
  }

  public goBack() {
    this._router.navigate(['/subscriptions']);
  }

}
