import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserLocalStorageObject } from '../shared/local-storage.service';

class LoginReturnData {
  success!: boolean;
  userId!: number;
  token!: string;
  subscription!: string;
}

export class LoginReturnError {
  stringCode!: string;
  value!: string | { waitTime: string };
}

@Injectable({
  providedIn: 'root'
})

export class LoginService {
  userData = new BehaviorSubject<UserLocalStorageObject|null>(null);

  constructor(private _http: HttpClient, private _router: Router) { }

  resetPassword(email: string) {
    return this._http.post<String>(environment.serverUrl + '/user/recoverPassword', { email })
      .pipe(
        map(responseData => {
          return responseData;
        }),
        catchError(errorRes => {
          return throwError(errorRes);
        })
      );
  }

}
