import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { LoadingService } from '../shared/loading-spinner/loading.service';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss']
})
export class UserActivationComponent {

  public showSuccessMsg = false;

  constructor(private _route: ActivatedRoute, private _router: Router, private _loginService : LoginService, private _loadingService: LoadingService) { }

  ngOnInit() {
    this._route.params.subscribe(params => {
      if (!params['activationCode']) {
        return this._router.navigate(['/login']);
      }
      this._loginService.activateUser(params['activationCode']).subscribe({
        next:(result)=> {
          this.showSuccessMsg = true;
        },
        error: (error) => {
          return this._router.navigate(['/login']);
        }
      });
      return;
    });

  }

  public openLoginPage(): void {
    this._router.navigate(['/login']).then(() => {
      this._loadingService.setHttpProgressStatus(false);
    });
  }

}
