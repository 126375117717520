import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { Pilot } from 'src/app/shared/models/pilot.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_PILOTS = '/pilots/get',
  INSERT_PILOT = '/pilot/insert',
  UPDATE_PILOT = '/pilot/update',
  DELETE_PILOT = '/pilot/delete'
}

@Injectable({
  providedIn: 'root'
})
export class PilotsService {

  constructor(private _http: HttpClient) { }


  public getPilots(): Observable<Pilot[]> {
    return this._http.get<Pilot[]>(environment.serverUrl + EndpointRoutes.GET_PILOTS, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public getPilot(pilotId: string): Observable<Pilot> {
    return this._http.get<Pilot>(environment.serverUrl + EndpointRoutes.GET_PILOTS, { headers: HttpUtils.createHeaders(), params: { id: pilotId } }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public savePilot(pilotObject: Pilot): Observable<Pilot> {
    return this._http.put<Pilot>(environment.serverUrl + EndpointRoutes.INSERT_PILOT, { pilot: pilotObject }, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public updatePilot(pilotObject: Pilot): Observable<Pilot> {
    return this._http.put<Pilot>(environment.serverUrl + EndpointRoutes.UPDATE_PILOT, { pilot: pilotObject }, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public deletePilot(id: string): Observable<any> {
    return this._http.delete(environment.serverUrl + EndpointRoutes.DELETE_PILOT, { headers: HttpUtils.createHeaders(), params: { id } }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }
}
