import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User} from 'src/app/shared/models/user.model';
import { LocalStorageService, UserLocalStorageObject } from 'src/app/shared/local-storage.service';
import { SharedService } from 'src/app/shared/shared.service';
import * as moment from 'moment';
import { SubscriptionsService } from '../subscriptions.service';
import { LoginService } from 'src/app/login/login.service';
import { LOCAL_STORAGE } from 'src/app/shared/constants';

@Component({
  selector: 'app-cart-result.paypal',
  templateUrl: './cart-result-paypal.component.html',
  styleUrls: ['./cart-result-paypal.component.scss']
})
export class CartResultPaypalComponent implements OnInit{

  public paymentStatus: string;
  public requestCode: string;
  public transaction: string;

  public userId: number;
  public user: User;
  public userSubscription: any;
  public daysLeft = '0';
  public expirationDateFormat = '0';
  public locale: string;

  private _userLocalStorageObject: UserLocalStorageObject;

  constructor(private _activatedRoute: ActivatedRoute, private _router: Router, private _sharedService: SharedService,
    private _subscriptionsService: SubscriptionsService, private _loginService: LoginService, private _localStorage: LocalStorageService) {}

  ngOnInit() {

    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this.locale = localStorage.getItem('locale') || 'it';
      this.userSubscription = this._sharedService.getSubscriptionTokenObject();

      this.paymentStatus = this._activatedRoute.snapshot.params['ps'];
      this.requestCode = this._activatedRoute.snapshot.params['rc'];
      this.transaction = this._activatedRoute.snapshot.params['tr'];
      this.locale = localStorage.getItem('locale') || 'it';
      if (!this.requestCode || !this.paymentStatus) {
        return this._router.navigate(['/main']);
      }

      if (this.paymentStatus === 'success') {
        const dayDiff = moment.duration(moment(this.userSubscription.to).diff(moment().format('YYYY-MM-DD')));
        this.expirationDateFormat = moment(this.userSubscription.to).format('DD/MM/YY');
        this.daysLeft = dayDiff.asDays().toFixed(0);
        return;
      }
      if (this.paymentStatus === 'cancelled') {
        // Nothing
        return;
      }
      if (this.paymentStatus === 'failed') {
        // Nothing
        return;
      }
      return;

    } else {
      this._loginService.logout();
      return;
    }
  }
}
