import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpUtils } from 'src/app/shared/http-utils';
import { User } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';

enum EndpointRoutes {
  GET_ACCOUNT = '/user/get',
  UPDATE_ACCOUNT = '/user/update'
}

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private _http: HttpClient) { }

  public getUser(): Observable<User> {
    console.log('header:',HttpUtils.createHeaders());
    return this._http.get<User>(environment.serverUrl + EndpointRoutes.GET_ACCOUNT, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public updateUser(userObject: User): Observable<User> {
    return this._http.put<User>(environment.serverUrl + EndpointRoutes.UPDATE_ACCOUNT, { user: userObject }, { headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }
}
