<div class="main-content" fxLayout="column" fxLayoutAlign="top center">
    <div class="container" style="width:90vw; min-height:80vh;">
        <div class="title-bar row" style="display:flex; width:100%;">
            <div class="maintitle"><h2>{{'ACCOUNT.pageTitle' | translate}}</h2></div>
        </div>
        <div class="pricing-table">
            <div class="container row">
                <div class="price-box">
                    <div class="card-body row">
                        <div class="card-text">
                          {{'ACCOUNT.grid.subscriptionInfos.daysLeft' | translate}}
                        </div>
                        <div class="card-value" *ngIf="daysLeft>=0;else daysLeftElseTmpl">{{daysLeft}}</div>
                        <ng-template #daysLeftElseTmpl>
                          <div class="card-value">{{'ACCOUNT.grid.subscriptionInfos.expired' | translate}}</div>
                        </ng-template>
                    </div>
                </div>
                <div class="price-box">
                    <div class="card-body row">
                        <div class="card-text">
                          {{'ACCOUNT.grid.subscriptionInfos.expirationDate' | translate}}
                        </div>
                        <div class="card-value">{{expirationDateFormat}}</div>
                    </div>
                </div>
                <div class="price-box">
                    <div class="card-body row">
                        <div class="card-text">
                          {{'ACCOUNT.grid.subscriptionInfos.pilotsMax' | translate}}
                        </div>
                        <div class="card-value">
                            {{userSubscription.subscription.pilots < 0 ? ('ACCOUNT.grid.subscriptionInfos.unlimitedLabelM' | translate) :userSubscription.subscription.pilots}}
                        </div>
                    </div>
                </div>
                <div class="price-box">
                    <div class="card-body row">
                        <div class="card-text">
                          {{'ACCOUNT.grid.subscriptionInfos.aircraftsMax' | translate}}
                        </div>
                        <div class="card-value">
                            {{userSubscription.subscription.helicopters < 0 ? ('ACCOUNT.grid.subscriptionInfos.unlimitedLabelM' | translate) :userSubscription.subscription.helicopters}}
                        </div>
                    </div>
                </div>
                <div class="price-box">
                    <div class="card-body row">
                        <div class="card-text">
                          {{'ACCOUNT.grid.subscriptionInfos.plansMax' | translate}}
                        </div>
                        <div class="card-value">
                            {{userSubscription.subscription.plans < 0 ? ('ACCOUNT.grid.subscriptionInfos.unlimitedLabelF' | translate) :userSubscription.subscription.plans}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <form class="k-form k-form-horizontal" [formGroup]="formGroup">
            <kendo-tabstrip class="tab-wrapper">
                <kendo-tabstrip-tab title="{{'ACCOUNT.grid.tabs.myAccount.title' | translate}}" [selected]="true">
                    <ng-template kendoTabContent>
                        <div class="page-content">
                            <div class="title">{{'ACCOUNT.grid.tabs.myAccount.title' | translate}}</div>
                            <div class="subtitle">{{'ACCOUNT.grid.tabs.myAccount.subTitle' | translate}}</div>
                            <fieldset class="k-form-fieldset">
                                <div class="row form-section">
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="firstName" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldFirstName.label' | translate}}"> </kendo-label>
                                                <kendo-textbox formControlName="firstName" [clearButton]="true" #firstName> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldFirstName.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['firstName'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="lastName" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldLastName.label' | translate}}"> </kendo-label>
                                                <kendo-textbox formControlName="lastName" [clearButton]="true" #lastName> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldLastName.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['lastName'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="email" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldEmail.label' | translate}}"> </kendo-label>
                                                <kendo-textbox formControlName="email" [clearButton]="true" #email> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldEmail.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                                <kendo-formerror *ngIf="formGroup.controls['email'].hasError('email')"><i>{{'SHARED.form.emailFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="cc_email" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldEmailCC.label' | translate}}"> </kendo-label>
                                                <kendo-textbox formControlName="cc_email" [clearButton]="true" #cc_email> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldEmailCC.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['cc_email'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                                <kendo-formerror *ngIf="formGroup.controls['cc_email'].hasError('email')"><i>{{'SHARED.form.emailFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="phone" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldPhone.label' | translate}}"> </kendo-label>
                                                <kendo-textbox formControlName="phone" [clearButton]="true" #phone> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldPhone.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['phone'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                          <kendo-formfield orientation="horizontal">
                                            <kendo-label class="k-label" [for]="language" text="{{'ACCOUNT.grid.tabs.myAccount.form.fieldLanguage.label' | translate}}"> </kendo-label>
                                            <kendo-combobox formControlName="language" [data]="languagesArray" textField="text" valueField="value" placeholder="Language" #language></kendo-combobox>
                                            <kendo-formhint>{{'ACCOUNT.grid.tabs.myAccount.form.fieldLanguage.hint' | translate}}</kendo-formhint>
                                            <kendo-formerror *ngIf="formGroup.controls['language'].hasError('required')"><i>{{'SHARED.form.mandatoryError' | translate}}</i></kendo-formerror>
                                          </kendo-formfield>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-section">
                                    <div class="row">
                                        <div class="col-12 align right">
                                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                              {{'SHARED.form.save' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="{{'ACCOUNT.grid.tabs.companyData.title' | translate}}">
                    <ng-template kendoTabContent>
                        <div class="page-content">
                            <div class="title">{{'ACCOUNT.grid.tabs.companyData.title' | translate}}</div>
                            <div class="subtitle">{{'ACCOUNT.grid.tabs.companyData.subTitle' | translate}}</div>
                            <fieldset class="k-form-fieldset">
                                <div class="row form-section">
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfName" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfName.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfName" [clearButton]="true" #pdfName> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfName.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfAddress" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfAddress.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfAddress" [clearButton]="true" #pdfAddress> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfAddress.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfCity" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfCity.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfCity" [clearButton]="true" #pdfCity> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfCity.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfPostCode" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPostCode.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfPostCode" [clearButton]="true" #pdfPostCode> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPostCode.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfEmail" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfEmail.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfEmail" [clearButton]="true" #pdfEmail> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfEmail.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['pdfEmail'].hasError('email')"><i>{{'SHARED.form.emailFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfPEC" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPEC.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfPEC" [clearButton]="true" #pdfPEC> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPEC.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['pdfPEC'].hasError('email')"><i>{{'SHARED.form.emailFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfPhone" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPhone.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfPhone" [clearButton]="true" #pdfPhone> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfPhone.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="pdfFax" text="{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfFax.label' | translate}}"></kendo-label>
                                                <kendo-textbox formControlName="pdfFax" [clearButton]="true" #pdfFax> </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.companyData.form.fieldPdfFax.hint' | translate}}</kendo-formhint>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-section">
                                    <div class="row">
                                        <div class="col-12 align right">
                                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                              {{'SHARED.form.save' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="{{'ACCOUNT.grid.tabs.passwordChange.title' | translate}}">
                    <ng-template kendoTabContent>
                        <div class="page-content">
                            <div class="title">{{'ACCOUNT.grid.tabs.passwordChange.title' | translate}}</div>
                            <div class="subtitle" [innerHtml]="'ACCOUNT.grid.tabs.passwordChange.subTitle' | translate">
                            </div>
                            <fieldset class="k-form-fieldset">
                                <div class="row form-section">
                                    <div class="row form-row">
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal" type=”password”>
                                                <kendo-label class="k-label" [for]="password" text="{{'ACCOUNT.grid.tabs.passwordChange.form.fieldPassword.label' | translate}}"> </kendo-label>
                                                <kendo-textbox
                                                    formControlName="password"
                                                    #password
                                                    [clearButton]="true"
                                                    type="password"
                                                >
                                                </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.passwordChange.form.fieldPassword.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['password'].hasError('pattern')"><i>{{'SHARED.form.passwordFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                        <div class="col-6 col-md-12">
                                            <kendo-formfield orientation="horizontal">
                                                <kendo-label class="k-label" [for]="repeatPassword" text="{{'ACCOUNT.grid.tabs.passwordChange.form.fieldRepeatPassword.label' | translate}}"> </kendo-label>
                                                <kendo-textbox
                                                    formControlName="repeatPassword"
                                                    #repeatPassword
                                                    [clearButton]="true"
                                                    type="password"
                                                >
                                                </kendo-textbox>
                                                <kendo-formhint>{{'ACCOUNT.grid.tabs.passwordChange.form.fieldRepeatPassword.hint' | translate}}</kendo-formhint>
                                                <kendo-formerror *ngIf="formGroup.controls['repeatPassword'].hasError('pattern')"><i>{{'SHARED.form.passwordFormatError' | translate}}</i></kendo-formerror>
                                            </kendo-formfield>
                                        </div>
                                    </div>
                                </div>
                                <div class="buttons-section">
                                    <div class="row">
                                        <div class="col-12 align right">
                                            <button kendoButton [primary]="true" (click)="saveChanges()">
                                              {{'SHARED.form.save' | translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </form>
    </div>
</div>
