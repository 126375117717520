import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { LoadingService } from '../shared/loading-spinner/loading.service';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { filter, forkJoin, switchMap, tap } from 'rxjs';
import { SharedService } from '../shared/shared.service';
import { environment } from 'src/environments/environment';
import { LoginReturnError, LoginService } from './login.service';
import { EncryptDecryptService } from '../shared/encrypt-decrypt.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@progress/kendo-angular-notification';
import { map } from "rxjs/operators";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit{

  @ViewChild('loginForm')
  loginForm!: NgForm;

  @ViewChild("password")
  public passwordField!: TextBoxComponent;

  public loginFormGroup: FormGroup;
  public errorMessage!: String;

  constructor(private _formBuilder: FormBuilder, private _router: Router, private _route: ActivatedRoute,
    private _loginService: LoginService, private _loadingService: LoadingService, private _sharedService: SharedService,
    private _encryptDecryptService: EncryptDecryptService, private _translate: TranslateService, private _notificationService: NotificationService,
    ) {
      this._sharedService.manageLocale();
      this.loginFormGroup = this._formBuilder.group({
        'username': ['', <any>Validators.required],
        'password': ['', <any>Validators.required]
      });
  }

  public ngOnInit(): void {
    this._route.queryParams
      .subscribe((params: Params) => {
        if (params["mode"]) {
          const mode: string = params['mode'];
          const paramString = this._encryptDecryptService.decryptData(params['params']);
          if (mode.toLowerCase() === 'autologin') { // autologin happen when from the app is needed to buy a new subscription (subscription are only in frontend available).
            const paramArray = paramString.split('|');
            const username = paramArray[0];
            const password = paramArray[1];
            const redirectToPage = paramArray[2];
            const paramDateTime = moment(paramArray[3], 'YYYYMMDDhhmm').toDate();
            const currentDataTime = this.getNowUTC();
            console.log('paramDateTime', paramDateTime);
            console.log('currentDateTime', currentDataTime);
            const minutesBetweenDates = (currentDataTime.getTime() - paramDateTime.getTime()) / (1000 * 60); //convert milliseconds to minutes
            if (minutesBetweenDates <= 15) //autologin valid only for 15 minute
            this._doLogin(username, password, redirectToPage);
          }
        }
      });
  }

  public ngAfterViewInit(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public toggleVisibility(): void {
    const passwordEl = this.passwordField.input.nativeElement;
    passwordEl.type = passwordEl.type === "password" ? "text" : "password";
  }

  public onLoginFormSubmit(): void {

    this.loginFormGroup.markAllAsTouched();
    const usernameControl = this.loginFormGroup.get('username');
    if (usernameControl) {
      usernameControl.setErrors(null);
      if (this.loginFormGroup.valid) {
        this._doLogin(
          this.loginFormGroup.value.username.trim(),
          this.loginFormGroup.value.password.trim(),
          '/plans'
          )
      }
    }
  }

  private _doLogin(username: string, password: string, redirectToPage: string) {
    this._loginService.loginPost(username, password).pipe(
      switchMap(() => this._loginService.userData),
      filter(userData => !!userData),
      tap(userData => {
        localStorage.setItem('userData', JSON.stringify(userData));
        localStorage.setItem('X-Access-Token', userData!.sessionToken);
      }),
      switchMap(userData =>
        this._sharedService.getLoggedUser().pipe(
          map(accountData => ({ userData, accountData }))
        )
      ),
      tap(({ userData, accountData }) => {
        localStorage.setItem('locale', userData!.locale);
        localStorage.setItem('accountData', JSON.stringify(accountData));
        const menus = this._sharedService.getMenus(userData!.locale);
        localStorage.setItem('menus', JSON.stringify(menus));
        this._sharedService.setCookie('locale', userData!.locale, 365);
      })
    ).subscribe({
      next: () => {
        this._router.navigate([redirectToPage]).then(() => {
          this._loadingService.setHttpProgressStatus(false);
          this._sharedService.manageLocale();
        });
      },
      error: (errorCode: LoginReturnError) => {
        this._notificationService.show({
          content: this._translate.instant('LOGIN.errors.' + errorCode.stringCode),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'error', icon: true },
          hideAfter: 2000,
        });
      }
    });
  }

  public clearForm(): void {
    this.loginFormGroup.reset();
  }

  public openRecoveryPage(): void {
    this._router.navigate(['/recover-password']).then(() => {
      this._loadingService.setHttpProgressStatus(false);
    });
  }

  public openRegistrationPage(): void {
    this._router.navigate(['/register']).then(() => {
      this._loadingService.setHttpProgressStatus(false);
    });
  }

  private getNowUTC() {
    const now = new Date();
    return new Date(now.getTime() + (now.getTimezoneOffset() * 60000));
  }

}
