import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AddEvent, DataBindingDirective, DataStateChangeEvent, GridDataResult, RemoveEvent } from '@progress/kendo-angular-grid';
import { PagerPosition, PagerType } from '@progress/kendo-angular-grid/pager/pager-settings';
import { process, State } from '@progress/kendo-data-query';
import { IconsModule } from '@progress/kendo-angular-icons';
import { Aircraft } from 'src/app/shared/models/aircraft.model';
import { User } from 'src/app/shared/models/user.model';
import { AircraftsService } from './aircrafts.service';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { SharedService } from 'src/app/shared/shared.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-aircrafts',
  templateUrl: './aircrafts.component.html',
  styleUrls: ['./aircrafts.component.scss']
})
export class AircraftsComponent {
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: GridDataResult;
  public mySelection: string[] = [];
  public aircraftsDataArray: Aircraft[] = [];
  public pageCode: string;

  public hiddenForMobile: boolean = false;
  public fixedWidth: number = 150;

  public blockInsertError: boolean = false;
  public subscriptionExpiredError: boolean = false;

  public dialogConfirmDeleteActive: boolean = false;
  private _currentRemoveEvent: RemoveEvent;

  private _varPagerTypes: PagerType = 'numeric';
  private _varPagerPosition: PagerPosition = 'both';

  private _userSubscription: any;
  private _currentUser: User;
  private _maxAircraftsAllowed: number;
  private _currentAircraftsCount: number;

  public pagingSettings = {
    pagerTypes: this._varPagerTypes,
    type: 'input',
    // buttonCount: 5,
    info: true,
    previousNext: true,
    position: this._varPagerPosition,
    pageSize: 10,
    responsive: true,
    pageSizes: [
      5,
      10,
      20,
      50,
      100,
    ]
  };

  public sortingSettings = {
    multiple: true,
    allowUnsort: true,
  };

  public state: State = {
    skip: 0,
    take: this.pagingSettings.pageSize,
    // Initial filter descriptor
  };

  public showHeader = false;

  public editDataItem: Aircraft | undefined;
  public isNew: boolean;

  constructor(private _aircraftsService: AircraftsService, private _notificationService: NotificationService,
    private _sharedService: SharedService, private _router: Router, private _translate: TranslateService) {
      this.hiddenForMobile = (window.innerWidth <= 1024);
      if (window.innerWidth <= 768) {
        this.fixedWidth = 100;
        this.pagingSettings.pageSize = 5;
        this.state.take = 5;
      }
    };

  public ngOnInit(): void {

    const lsLoggedUser = localStorage.getItem('accountData');
    if (lsLoggedUser) {
      this._currentUser = JSON.parse(lsLoggedUser);
      this._userSubscription = this._sharedService.getSubscriptionTokenObject();
      console.log('subscription', this._userSubscription);
      this._maxAircraftsAllowed = this._userSubscription.subscription.aircrafts;
      this._setGridData();
    }

  }

  private _setGridData() {
    this._aircraftsService.getAircrafts().subscribe((result) => {
      this.aircraftsDataArray = result;
      this._currentAircraftsCount = result.length;
      this.gridData = process(result, this.state);
    });
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridData = process(this.aircraftsDataArray, this.state);
  }

  public onFilter(input: Event): void {
    const inputValue = (input.target as HTMLInputElement).value;
    if (inputValue) {
      this.state.filter =
        {
          logic: "or",
          filters: [
            {
              field: "type",
              operator: "contains",
              value: inputValue,
            },
            {
              field: "name",
              operator: "contains",
              value: inputValue,
            }
          ],
      };
    } else {
      this.state.filter = undefined;
    }
    this.state.skip = 0;
    this.gridData = process(this.aircraftsDataArray, this.state);
  }

  public onStateChange(state: State): void {
    this.state = state;
    this._setGridData();
  }

  public addHandler(): void {
    this.editDataItem = new Aircraft();
    this.isNew = true;
    this.blockInsertError = false;
    this.subscriptionExpiredError = false;
    if (this._currentAircraftsCount >= 1 && this._userSubscription.isTrial) {
      this.blockInsertError = true;
    }
    const dayDiff = moment.duration(moment(this._userSubscription.afterExpiredValidity).diff(moment().format('YYYY-MM-DD')));
    if (dayDiff.asDays() < 0) {
      this.subscriptionExpiredError = true;
    }
  }

  public editHandler(args: AddEvent): void {
    this.editDataItem = args.dataItem;
    this.blockInsertError = false;
    this.subscriptionExpiredError = false;
    this.isNew = false;
  }

  public cancelHandler(): void {
    this.editDataItem = undefined;
    this.blockInsertError = false;
    this.subscriptionExpiredError = false;
  }

  public saveHandler(aircraft: Aircraft): void {
    if (this.isNew) {
      this._insertAircraft(aircraft);
    } else {
      this._updateAircraft(aircraft);
    }
  }

  public removeConfirmHandler(args: RemoveEvent): void {
    this.dialogConfirmDeleteActive = true
    this._currentRemoveEvent = args;
  }

  public dialogConfirmDeleteHandler(action:string) {
    switch (action.toLowerCase()) {
      case 'yes':
        this.removeHandler(this._currentRemoveEvent);
        this.dialogConfirmDeleteActive = false;
        break;
      case 'no':
        this.dialogConfirmDeleteActive = false;
        break;
    }
  }

  public removeHandler(args: RemoveEvent): void {
    this._aircraftsService.deleteAircraft(args.dataItem.id).subscribe({
      complete:()=> {
        this._notificationService.show({
          content: this._translate.instant('SHARED.messages.deleteConfirmed'),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 3000,
        });
        this._setGridData();
      },
      error: (error) => {
        console.log('error___this._aircraftsService.deletePilot', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: error.error,
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });

        notification.afterHide?.subscribe(()=> {
          this._setGridData();
        });
      }
    });
  }

  public goToSubscriptionsHandler(): void {
    this._router.navigate(['/subscriptions']);
  }

  private _insertAircraft(aircraft: Aircraft): void {
    this._aircraftsService.saveAircraft(aircraft).subscribe({
      complete:()=> {
        this._notificationService.show({
          content: this._translate.instant('SHARED.messages.insertConfirmed'),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 3000,
        });
        this._setGridData();
      },
      error: (error) => {
        console.log('error___this._aircraftsService.saveAircraft', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: error.error,
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });

        notification.afterHide?.subscribe(()=> {
          this._setGridData();
        });
      }
    });
  }

  private _updateAircraft(aircraft: Aircraft): void {
    this._aircraftsService.updateAircraft(aircraft).subscribe({
      complete:()=> {
        this._notificationService.show({
          content: this._translate.instant('SHARED.messages.updateConfirmed'),
          animation: { type: 'slide', duration: 500 },
          position: { horizontal: 'center', vertical: 'bottom' },
          type: { style: 'success', icon: true },
          hideAfter: 3000,
        });
        this._setGridData();
      },
      error: (error) => {
        console.log('error___this._aircraftsService.updatePilot', error)
        const notification: NotificationRef =
          this._notificationService.show({
            content: error.error,
            animation: { type: 'slide', duration: 500 },
            position: { horizontal: 'center', vertical: 'bottom' },
            type: { style: 'error', icon: true },
            hideAfter: 3000,
          });

        notification.afterHide?.subscribe(()=> {
          this._setGridData();
        });
      }
    });
  }
}
