import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-privacy-text',
  templateUrl: './privacy-text.component.html',
  styleUrls: ['./privacy-text.component.scss']
})
export class PrivacyTextComponent {

  @Input() public language:string = 'it';

}
